import { Container, Row, Col, Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./new-products.css";
import davinesPicOne from "../../media/images/newProducts/img2.jpg";
import pic2 from "../../media/images/newProducts/img4.jpg";
import k18 from "../../media/images/newProducts/img6.jpg";
import video1 from "../../media/videos/video.mp4"
import { useEffect } from "react";
import ga from '../../googleAnalytics';
import { Helmet } from "react-helmet";


export const NewProducts = ({language}) => {
	
	useEffect(()=> {
		ga.trackPageView();
	  })

	return (
		<div>
			<Helmet>
			<title>Helmi Helsinki | Etusivu</title>
                <meta
                name="description"
                property="og:description"
                content="Helmi Helsingistä löydät laajan valikoiman palveluja niin naisille kuin miehille. Meiltä löydät parturi-, kampaamo-, kampaus-, meikki- sekä kynsipalveluita."
                />
                <meta property="og:title" content="uudet tuotteet" />
                <meta property="og:url" content="www.helmihelsinki.fi/uutuudet" />
                <meta property="og:image" content="https://scontent.fqlf1-2.fna.fbcdn.net/v/t39.30808-6/293368231_553616119803362_2817365091336273284_n.jpg?stp=dst-jpg_s960x960&_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=McW5Q9wcB6AAX-IzmcP&_nc_ht=scontent.fqlf1-2.fna&oh=00_AT_hbxD1SoinrUNlrKGicdA5v7CfGTTt4XETAhA-2lFMfQ&oe=62E4D528" />
                <meta
                name="keywords"
                content="helmihelsinki, helmi, helsinki, Helmi Helsinki, hairdresser, parturi, Makeup, salon, Helsinki, Mikonkatu, kampaamo, website, parturi-kampaamo, parturikampaamo, kampaaja, kampaamo helsinki, make up, hiukset, hår, frisyr, frisör, barberare, smink, meikkitaiteilija, meikki, häämeikki, hääkampaus, brudkammning, brudsminkning, gellack, geelilakkaus, geelikynnet, kynnet, uppsättning, kampaamo helsinki, paras kampaamo, Mikonkatu 13, helsingin keskusta, kampaus, Davines, K18Hair, k18hair, davines, uudettuotteet, hiushoidot, meikki"
                />
			</Helmet>
			<Container className="container-page">
				{/*FIRST PRODUCT*/}
				<Row>
					<Row><h2 className="new-products-name">Davines Instant Bonding Glow</h2></Row>
					<Row className="product-div-upper">
						'<Col xs={{span: 12, order: 2}} md={{offset: 1, span: 5, order: 1}}>
							{
								language === "fin" && 
								<div>
									<p>
										<b>Instant Bonding Glow</b> on nopeavaikutteinen poishuuhdeltava kiiltoseerumi. Se jättää hiukset erikoiskiiltäviksi, vahvantuntuisiksi ja helpoiksi selvittää.
										<br/>
										<br/>
										<span className="list-span">Biacidic Bond Complex korjaa ja vahvistaa sekä ehkäisee hiusvaurioita.</span>
										<span className="list-span">Illuminating Amino Concentrate vahvistaa hiuskuitua, antaa ekstrakiillon ja pidentää hiusvärin kestoa.</span>
										<br/>
										<span className="list-span">Baobab Extract -baobab-siemenuute on luonnon vaihtoehto silikoneille. Se kosteuttaa ja hoitaa hiusta parantaen sen joustavuutta ja tehden hiuksen helpommin käsiteltäväksi.</span>
										<br/>
										<br/>
										Välittömästi näkyviä tuloksia antava seerumihoito sopii erityisesti luonnostaan vaaleille tai vaalennetuille hiuksille. Sen helposti imeytyvä seerumikoostumus on erittäin kosteuttava, mutta ei latista hennoimpiakaan hiuksia.
										Koska tuote on alkoholiton, voit huoletta käyttää sitä heti värjäyksen, vaalennuksen tai minkä tahansa kemiallisen käsittelyn jälkeen. Se vahvistaa hiuksia, antaa suojaa värille ja luo samalla vaikuttavan viimeistelyn antaen uskomattoman kiillon.
										</p>
								</div>
							}
							{
								language === "eng" &&
								<div>
									<p>
										<b>Instant Bonding Glow</b> is a fast-acting rinse-out shine serum. It leaves hair with special shine, feeling stronger and easier to detangle.
										<br/>
										<br/>
										<span className="list-span">Biacidic Bond Complex repairs and strengthens hair, preventing damage.</span>
										<span className="list-span">Illuminating Amino Concentrate strengthens hair fibers, adds extra shine, and prolongs hair color life.</span>
										<br/>
										<span className="list-span">Baobab Extract - a natural alternative to silicone, moisturizes and nourishes hair, improving its elasticity and making it easier to manage.</span>
										<br/>
										<br/>
										This serum treatment provides immediately visible results and is particularly suitable for naturally blonde or lightened hair. Its easily absorbed serum texture is highly moisturizing, but does not weigh down even the finest hair.
										As the product is alcohol-free, it can be safely used immediately after coloring, bleaching, or any chemical treatment. It strengthens hair, protects color, and creates an impressive finish, giving incredible shine.
									</p>
								</div>
							}
							{
								language === "swe" &&
								<div>
									<p>
										<b>Instant Bonding Glow</b> är ett snabbverkande glansserum. Det ger håret en speciell glans, gör det starkare och lättare att reda ut.
										<br/>
										<br/>
										<span className="list-span">Biacidic Bond Complex reparerar och stärker håret, och förhindrar hårskador.</span>
										<span className="list-span">Illuminating Amino Concentrate stärker hårfibrerna, ger extra glans och förlänger hårfärgens hållbarhet.</span>
										<br/>
										<span className="list-span">Baobab Extract - baobab-fröextrakt är ett naturligt alternativ till silikoner. Det återfuktar och vårdar håret och förbättrar dess flexibilitet, vilket gör det lättare att hantera.</span>
										<br/>
										<br/>
										Denna serumbehandling ger omedelbara resultat och passar särskilt bra för naturligt blont eller blekt hår. Dess lättabsorberande serumkonsistens är mycket fuktgivande, men tynger inte ens de finaste håren.
										Eftersom produkten är alkoholfri kan du använda den utan oro direkt efter färgning, blekning eller annan kemisk behandling. Den stärker håret, ger skydd åt färgen och ger en imponerande finish med otrolig glans.
										</p>
								</div>
							}
							
						</Col>
						<Col xs={{span: 12, order: 1}} md={{span: 3, offset: 1, order: 2}}>
							<Image alt="Davines" src={davinesPicOne} className="new-products-pic"></Image>
						</Col>
					</Row>
					<div className="lower-pics-div">
						<div className="player-wrapper">
							<ReactPlayer
								url={video1}
								playing={true}
								controls={true}
								className="react-player"
								width="100%"
								height="100%"
							/>
						</div>
						<Image
							alt="product image"
							src={pic2}
							className="lower-pic"
						/>
					</div>
				</Row>



				{/*SECOND PRODUCT*/}
				<Row>
				<Row><h2 className="new-products-name">K18Hair Peptide Prep Pro chelating complex</h2></Row>
					<Row className="product-div-upper">
						'<Col xs={{span: 12, order: 2}} md={{offset: 1, span: 5, order: 2}}>
							{
								language === "fin" &&
								<div>
									<p>
										<b>K18Hair PEPTIDE PREP PRO chelating complex</b> poistaa 7 eri metallityyppiä ja mineraalikertymiä hiuksista vain 4 minuutissa!
										<br/>
										<br/>
										Täysin uusi, ainutlaatuinen teknologia puhdistaa hiuksen metalleista ja mineraalikertymistä valmistaen hiuksen kemialliseen käsittelyyn.
										<span className="list-span"><span className="first-span"></span>5 kelatoivaa ainesosaa yhdessä muodostavat tehokkaan metallien ja mineraalien poiston.</span>
										<br/>
										<span className="list-span">Poistaa seuraavia metalleja: kupari, kalsium, magnesium, sinkki, nikkeli, kadmium, lyijy</span>
										<br/>
										<span className="list-span">Ei vie ylimääräistä aikaa – vaikutusaika vain 4 minuuttia!</span>
										<br/>
										<span className="list-span">Voidaan käyttää kaikkien kemiallisten käsittelyiden kanssa, mutta myös metallinpoistohoitona yksinään, ilman
										kemiallista käsittelyä</span>
										<br/>
										<span className="list-span">Tuo esiin hiusten luonnollisen tekstuurin ja palauttaa metallien samentaman hiusvärin kirkkaaksi</span>
										<br/>
										<span className="list-span">Lopputuloksena puhtaampi hiusväri, vähemmän katkeilua ja hiusten murtumista vaalennus- ja värikäsittelyn aikana. Ennalta-arvattavat ja tasaiset värjäystulokset.</span>
										<br/>
										<br/>
										Täysin ennennäkemätön metallien ja mineraalien poisto, joka perustuu uuteen bioteknologiseen innovaatioon. Tuloksena puhtaampi hiusväri, vähemmän hiusten katkeilua vaalennus- ja värikäsittelyn aikana sekä ennaltasuunnitellun mukaiset ja tasaiset värjäystulokset. K18Hair chelating hair complexia voidaan käyttää kaikkien kemiallisten käsittelyiden yhteydessä tai yksinään ilman kemiallista käsittelyä.
									</p>
								</div>
							}
							{
								language === "eng" && 
								<div>
									<p>
										<b>K18Hair PEPTIDE PREP PRO chelating complex</b> removes 7 different types of metals and mineral buildup from hair in just 4 minutes!
										<br/>
										<br/>
										A completely new and unique technology cleanses the hair of metals and mineral buildup, preparing the hair for chemical treatment or K18Hair molecular repair.

										<span className="list-span"><span className="first-span"></span>5 chelating ingredients together form effective removal of metals and minerals.</span>
										<br/>
										<span className="list-span">Removes the following metals: copper, calcium, magnesium, zinc, nickel, cadmium, lead.</span>
										<br/>
										<span className="list-span">Doesn't take extra time - the exposure time is only 4 minutes!</span>
										<br/>
										<span className="list-span">Can be used with all chemical treatments, but also as a stand-alone metal removal treatment without chemical treatment.</span>
										<br/>
										<span className="list-span">Brings out the hair's natural texture and restores metal dimmed haircolor to clarity.</span>
										<br/>
										<span className="list-span">Provides cleaner hair color, less breakage and hair loss during bleaching and coloring. Predictable and more even color results.</span>
										<br/>
										<br/>
										A completely unprecedented removal of metals and minerals, based on a new biotechnological innovation. The result is a cleaner hair color, less hair breakage during bleaching and coloring treatments, as well as predictable and consistent coloring results. K18Hair chelating hair complex can be used with all chemical treatments or alone without any chemical treatment.
									</p>
								</div>
							}
							{
								language === "swe" &&
								<div>
									<p>
										<b>K18Hair PEPTIDE PREP PRO chelating complex</b> avlägsnar 7 olika metalltyper och mineralavlagringar från håret på bara 4 minuter!
										<br/>
										<br/>
										Helt ny och unik teknologi rengör håret från metaller och mineralavlagringar för att förbereda håret för kemisk behandling.

										<span className="list-span"><span className="first-span"></span>5 kelaterande ingredienser bildar tillsammans en effektiv borttagning av metaller och mineraler.</span>
										<br/>
										<span className="list-span"> Avlägsnar följande metaller: koppar, kalcium, magnesium, zink, nickel, kadmium, bly.</span>
										<br/>
										<span className="list-span">Tar inte extra tid – verkningstiden är bara 4 minuter!</span>
										<br/>
										<span className="list-span">Kan användas med alla kemiska behandlingar, men även som en ensam metallavlägsnande behandling utan kemisk behandling.</span>
										<br/>
										<span className="list-span"> Framhäver hårets naturliga textur och återställer färgglansen klar igen.</span>
										<br/>
										<span className="list-span">Ger renare hårfärg, mindre håravfall och håravbrott under blekning och färgbehandling. Förutsägbara och jämnare färgresultat.</span>
										<br/>
										<br/>
										Oöverträffbar borttagning av metaller och mineraler baserad på en ny bioteknologisk innovation. Resultatet är renare hårfärg, mindre håravfall under blekning och färgning samt förutsägbara och jämnt färgade resultat. K18Hair chelating hair complex kan användas tillsammans med alla kemiska behandlingar eller ensamt utan kemisk behandling.
									</p>
								</div>
							}
						</Col>
						<Col xs={{span: 12, order: 1}} md={{span: 3, offset: 1, order: 1}}>
							<Image alt="Davines" src={k18} className="new-products-pic"></Image>
						</Col>
					</Row>
				</Row>
			</Container>
		</div>
	)
}