import { Modal, Image } from "react-bootstrap";
import logo from '../../media/images/hnet.com-image.ico'
import './gallery.css'

export const ModalPic = (props) => {
    return(
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton >
        <Modal.Title id="contained-modal-title-vcenter">
          <Image alt="logo" src={logo} className="modal-gallery-logo"></Image>
          Helmi Helsinki - Gallery
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img alt="Gallery selected big pic" src={props.url} className="modal-pic"></img>
      </Modal.Body>
    </Modal>
    )
}