import { Container, Row, Col, Button } from "react-bootstrap";
import "./servicePage.css";
import { CardService } from "../cardService/cardService";
import { listOfServices } from "./listOfServices";
import { Outlet, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import homepagePic from "../../media/images/homepagePic.png";
import ga from '../../googleAnalytics';
import { useEffect } from "react";

export const ServicePage = ({ language }) => {
  const navigate = useNavigate();
  const handleNavigate = (location) => {
    ga.trackGAEvent('choose_services', 'services', "location");
    navigate(location)
  }

  useEffect(()=> {
    ga.trackPageView();
  }, [])

  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Palvelut</title>
        <meta
          name="description"
          property="og:description"
          content="Täältä löydät Helmi Helsingin kaikki palvelut."
        />
        <meta property="og:title" content="Palvelut" />
        <meta property="og:url" content="www.helmihelsinki.fi/services" />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, palvelut, services,tjänster, kampaamopalvelut, frisörtjänster, barberartjänster, service, parturikampaamopalvelut, meikki "
        />
      </Helmet>
      <Container className="container-page">
        <Row>
          <Col
            style={{
              textAlign: "center",
              margin: "4vh 0",
              letterSpacing: "1vh",
            }}
          >
            <h1>
              {language === "fin" && "Palvelut"}
              {language === "eng" && "Services"}
              {language === "swe" && "Våra tjänster"}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={2} id="service-list-names">
            {listOfServices.map((service) => (
              <Row key={service.title.fin}>
                <Col className="card-title" style={{ textAlign: "start" }}>
                  <Link
                    to={`/services/${service.path.toLowerCase()}`}
                    className="LinkComponent"
                    onClick={()=>ga.trackGAEvent('choose_services', 'services', service.path.toLowerCase())}
                  >
                    {language==='fin' && service.title.fin.toUpperCase()}
                    {language==='eng' && service.title.eng.toUpperCase()}
                    {language==='swe' && service.title.swe.toUpperCase()}
                  </Link>
                </Col>
              </Row>
            ))}
          </Col>
          <Col md={10}>
            <Container>
              <Row>
                {listOfServices.map((service) => (
                  <Col
                    style={{ overflow: "hidden" }}
                    xs={12}
                    md={4}
                    key={service.id}
                  >
                    <CardService
                      title={service.title}
                      url={service.url}
                      path={service.path}
                      language={language}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
        <Row style={{ margin: "2vh 0" }}>
          <Col xs={{ span: 5, offset: 5 }}>
            <Button variant="dark" onClick={() => handleNavigate("/booking")}>
              {language==='fin' && "Varaa nyt!"}
              {language==='eng' && "Book now!"}
              {language==='swe' && "Boka nu!"}
            </Button>
          </Col>
        </Row>
        <Outlet />
      </Container>
    </div>
  );
};
