import { Col, Container, Row, Image, Button, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SideNavServices } from "./sideNavServices";
import { ripsetJaKulmat } from "../../prices/listOfPrices";
import "./individualServices.css";
import { AccordionComponent } from "../../accordion";
import Helmet from "react-helmet";
import homepagePic from "../../../media/images/homepagePic.png";
import kulmatHeadPic from "../../../media/images/ripsetjakulmat/top_pic.jpg";
import { useEffect } from "react";
import ga from "../../../googleAnalytics";
import { CheckAllPrices } from "../../CheckAllPrices";

export const RipsetJaKulmatPage = ({ language }) => {
  const navigate = useNavigate();
  const handleNavigate = (location, label) => {
    ga.trackGAEvent("follow_button", "individualServices_button", label);
    navigate(location);
  };
  const allServices = ripsetJaKulmat.filter(
    (ser) => typeof ser.id === "number"
  );

  useEffect(() => {
    ga.trackPageView();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Ripset ja kulmat</title>
        <meta
          name="description"
          property="description"
          content="Ripsien ja kulmien värjäys ja muotoilu palvelut."
        />
        <meta property="og:title" content="Ripset ja kulmat" />
        <meta
          property="og:url"
          content="www.helmihelsinki.fi/services/ripsetjakulmat"
        />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, Services, palvelut, Ripset, kulmat, kulmien värjäys, ripsien värjäys, kulmien siistiminen, kulmien nyppiminen, siistit kulmat, kulmien värjäys helsinki, ögonbryn, ögonfransar, plocka ögonbryn, snygga ögonbryn, siistit kulmat, ripset ja kulmat, ripset ja kulmat helsinki, färga ögonbryn, färga ögonfransar, snygga ögonbryn"
        />
      </Helmet>
      <Container className="container-page">
        <Row>
          <Col lg={4}>
            <Image
              alt="back button"
              src="https://img.icons8.com/ios/344/circled-left-2.png"
              className="service-back-btn"
              onClick={() => handleNavigate("/services", "return")}
            ></Image>
          </Col>
          <Col lg={8} className="individual-service-title">
            <h1>
              {language === "fin" && "Ripset ja kulmat"}
              {language === "eng" && "Eyebrows and lashes"}
              {language === "swe" && "Ögonbryn och fransar"}
            </h1>
          </Col>
        </Row>
        <Row style={{ margin: "5vh 0" }}>
          <Col md={3} className="hiddePhone">
            <SideNavServices language={language} />
          </Col>
          <Col md={9}>
            <Row className="individual-service-info">
              <Col xs={12} lg={5}>
                <Image
                  alt="haircut"
                  className="individual-service-img"
                  src={kulmatHeadPic}
                ></Image>
              </Col>
              <Col
                style={{ fontSize: "1.2rem", liheHeight: "1.5rem" }}
                xs={12}
                lg={7}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <Col>
                    {language === "fin" && (
                      <div>
                        Huolitellut kulmat kehystävät kasvoja ja antavat niille
                        ryhtiä. Värjätyt ripset ja kulmat helpottavat ja
                        nopeuttavat arkeasi. Meiltä saat niin ripsien ja kulmien
                        värjäyksiä sekä kulmien muotoilun. Muotoilemme kulmat
                        pinsetillä.
                      </div>
                    )}
                    {language === "eng" && (
                      <div>
                        Stylish eyebrows frame your face and gives it a lift.
                        Colored eyelashes and eyebrows make your morning routine
                        fast and easy. You can get your eyelashes and eyebrows
                        colored, and your eyebrows shaped.
                        <br /> We shape the eyebrows with a tweeser.{" "}
                      </div>
                    )}
                    {language === "swe" && (
                      <div>
                        Snygga ögonbryn ger ansiktet ett lyft. Färgade
                        ögonfransar och ögonbryn gör din morgonrutin snabbare.
                        Hos oss får du dina ögonfransar och ögonbryn färgade,
                        samt ögonbrynen plockade. Vi plockar ögonbrynen med en
                        pincett.
                      </div>
                    )}
                  </Col>
                  <Col>
                    <Row>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.facebook.com/HelmiHelsinki13/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={() =>
                              ga.trackGAEvent(
                                "follow_button",
                                "individualServices_button",
                                "facebook"
                              )
                            }
                          >
                            <Image
                              alt="Facebook"
                              src="https://img.icons8.com/ios/344/facebook-new.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Facebook
                          </Button>
                        </a>
                      </Col>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.instagram.com/helmihelsinki/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={() =>
                              ga.trackGAEvent(
                                "follow_button",
                                "individualServices_button",
                                "instagram"
                              )
                            }
                          >
                            <Image
                              alt="Instagram"
                              src="https://img.icons8.com/ios/344/instagram-new--v1.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Instagram
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className="flex-center-column">
            <h4>
              {language === "fin" && "Ripsi ja kulma palvelumme"}
              {language === "eng" && "Our eyebrows and lashes services"}
              {language === "swe" && "Våra ögonbryn och frans behandlingar"}
            </h4>
          </Col>
        </Row>
        <section style={{ padding: "5vh 10%" }}>
          <Accordion defaultActiveKey="0">
            {allServices.map((item) => (
              <AccordionComponent
                id={item.id}
                title={item.name}
                key={item.id}
                description={item.description}
                price={item.price}
                language={language}
              />
            ))}
          </Accordion>
        </section>
        <CheckAllPrices language={language} />
      </Container>
    </div>
  );
};
