import "./frontpage.css";
import { Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ga from "../../googleAnalytics";

export const FrontpageComponent = ({ language }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    ga.trackGAEvent("frontpage_button_click", "frontpage_buttons", "book_now");
    navigate("/booking");
  };
  return (
    <Container fluid className="frontpage-container">
      <div className="frontpage-txt-box">
        <Row className="container-page">
          <h1 className="frontpage-title">
            Helmi Helsinki <br></br>{" "}
          </h1>
          <h2 id="frontpage-subtitle">Hair | Makeup</h2>
        </Row>
        <Row>
          <Button
            onClick={() => handleNavigate()}
            className="frontpage-btn"
            variant="outline-dark"
          >
            {language === "fin" && "Varaa nyt!"}
            {language === "eng" && "Book now!"}
            {language === "swe" && "Boka nu!"}
          </Button>
        </Row>
      </div>
    </Container>
  );
};
