import { Container, Row, Col, Image } from "react-bootstrap";
import "./contacts.css";
import GoogleMapsComponent from "../googleMaps";
import Helmet from "react-helmet";
import homepagePic from "../../media/images/homepagePic.png";
import email from "../../media/images/email.png";
import adress from "../../media/images/adress.png";
import phone from "../../media/images/phone.png";
import timetable from "../../media/images/timetable.png";
import { useEffect } from "react";
import ga from '../../googleAnalytics';

export const ContactsComponent = ({ language }) => {

  useEffect(()=>{
    ga.trackPageView();
  }, [])

  return (
    <div className="container-page" id="contacts-container">
      <Helmet>
        <title>Helmi Helsinki | Yhteystiedot</title>
        <meta
          name="description"
          property="og:description"
          content="Täältä näet mistä voit löytää meidät. Varaa aika nettiajanvarauksen kautta tai soita meille."
        />
        <meta property="og:title" content="Yhteystiedot" />
        <meta property="og:url" content="www.helmihelsinki.fi/contacts" />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, Yhteystiedot, contacts, yhteystiedot, kontaktuppgifter, kampaamo helsinki, frisör helsingfors, parturi helsinki"
        />
      </Helmet>
      <Container>
        <Row>
          <Col id="firstCol" xs={12} lg={6}>
            <Row>
              <Col id="contactMe">
                {language==="fin" && "Yhteystiedot"}
                {language==="eng" && "Contact us"}
                {language==="swe" && "Kontakta oss"}
              </Col>
            </Row>
            <Row className="contacts-info-box">
                <Col xs={12} lg={2} className="contacts-info-title">
                  <Image alt="phone" src={phone} className="contacts-icons"></Image>
                </Col>
              <Col xs={12} lg={9} className="contacts-info-detail">
                +358503413400
              </Col>
            </Row>
            <Row className="contacts-info-box">
                <Col className="contacts-info-title" xs={12} lg={2}>
                  <Image alt="email" src={email} className="contacts-icons"></Image>
                </Col>
              <Col className="contacts-info-detail" xs={12} lg={9}>
                helmihelsinki@hotmail.com
              </Col>
            </Row>
            <Row className="contacts-info-box">
                <Col className="contacts-info-title" xs={12} lg={2}>
                  <img alt="adress" src={adress} className="contacts-icons" id="adress-icon"></img>
                </Col>
              <Col className="contacts-info-detail" xs={12} lg={9}>
                {language === "fin" && "Mikonkatu 13, 00100 - Helsinki, Suomi"}
                {language === "eng" && "Mikonkatu 13, 00100 - Helsinki, Suomi"}
                {language === "swe" && "Mikaelsgatan 13, 00100 - Helsingfors, Finland"}
                <br />
                <br />
                {language==="fin" && "Sisäänkäynti OP:n oikealla puolella olevista lasiovista sisäpihan päätyyn. Tervetuloa!"}
                {language==="eng" && "Entrance from the big glass doors on the right side of Osuuspankki, then walk to the end of the court yard. Welcome!"}
                {language==="swe" && "Ingång genom stora glas dörrarna på högra sidan av OP, fortsätt rakt till ändan av innergården. Välkommen!"}
              </Col>
            </Row>
            <Row className="contacts-info-box">
                <Col className="contacts-info-title" xs={12} lg={2}>
                  <Image alt="timetable" src={timetable} className="contacts-icons"></Image>
                </Col>
              <Col className="contacts-info-detail" xs={12} lg={9}>
                {language=== 'fin' && <div><br/>Maanantai-Perjantai: 10.00 - 18.00 <br/> Lauantai: sopimuksen mukaan (9-16)</div>}
                {language=== 'eng' && <div> <br/>Monday-Friday: 10.00 - 18.00 <br/>Saturday: according to agreement (9-16)</div>}
                {language=== 'swe' && <div>Måndag-Fredag: 10.00-18.00 <br/> Lördag: enligt överenskommelse (9-16)</div>}
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Col style={{ width: "100%", marginTop: '5vh' }}>
              <GoogleMapsComponent />
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
