import { Container, Row, Col, Button} from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import './booktimeBanner.css';
import ga from '../../googleAnalytics';

export const BookTimeBanner = ({language}) => {
    
    const navigate=useNavigate();
    const handleNavigate = () => {
        ga.trackGAEvent('frontpage_button_click', 'frontpage_booktimeBanner_buttons', 'book_here');
        navigate('/booking');
    }

    return (
        <Container id="booking-banner-box" 
        data-aos="fade-down"
        data-aos-delay="0"
        data-aos-duration="1000"
        data-aos-mirror="false"
        data-aos-once='true'>
            <Row>
                <Col id="book-info-box" xs={{span:11, offset:0}} md={{span:5, offset:1}}>
                    <Row>
                        <Col id="booking-title">
                            {language === "fin" && "Varaa aikasi!"}
                            {language === "eng" && "Book your time!"}
                            {language === "swe" && "Boka din tid!"}
                        </Col>
                    </Row>
                    <Row>
                        <Col id="booking-description">
                            {language === "fin" && "Kaipaatko uutta hiustenleikkausta, piristystä hiusväriin tai kenties kauniit kynnet? Varaa aikasi meille hetkessä. Tervetuloa!"}
                            {language === "eng" && "Are you interested in a new haircolor a fresh haircut or pretty nails? Don't hesitate to book your appointment. Welcome!"}
                            {language === "swe" && "Behöver du en ny hårklippning en ny fräsch färg eller snygga naglar? Boka din tid hos oss. Välkommen!"}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button id="booking-btn" onClick={()=>handleNavigate()}>
                                {language === "fin" && "Varaa tästä"}
                                {language === "eng" && "Book here"}
                                {language === "swe" && "Boka här"}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}