import ReactGA from 'react-ga4';

const trackPageView = () => {
	ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
}


const trackGAEvent = (action, category, label) => {
	ReactGA.event({
		category,
		action,
		label
		});
}

const ga={
    trackGAEvent,
    trackPageView
}
export default ga