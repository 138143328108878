import './individualServices.css';
import {Row} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import {listOfServices} from '../listOfServices';
import ga from '../../../googleAnalytics';

export const SideNavServices = ({language}) => {
  return (
    <Row style={{ display: "flex", flexDirection: "column" }}>
      {listOfServices.map((service) => (
        <Link
          className="LinkComponent card-title"
          key={service.id}
          to={`/services/${service.path.toLowerCase()}`}
          style={{ textAlign: "start" }}
          onClick={()=>ga.trackGAEvent('choose_service', 'individualServices_sidenav', service.path.toLowerCase())}
        >
          {language === "fin" && service.title.fin.toUpperCase()}
          {language === "eng" && service.title.eng.toUpperCase()}
          {language === "swe" && service.title.swe.toUpperCase()}
        </Link>
      ))}
    </Row>
  );
};
