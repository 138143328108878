import color from "../../media/images/services_list/varjaykset.jpeg";
import muut from "../../media/images/services_list/hiushoidot.jpg";
import haircut from "../../media/images/services_list/leikkaukset.jpg";
import ripsetjakulmat from "../../media/images/services_list/ripsetjakulmat.jpg";
import KampauksetJaMeikit from "../../media/images/services_list/kampauksetjameikit.jpg";

export const listOfServices = [
  {
    id: 0,
    title: {
      fin: "Leikkaukset",
      eng: "Hair cut",
      swe: "Klippningar",
    },
    url: haircut,
    path: "leikkaukset",
  },
  {
    id: 1,
    title: {
      fin: "Värjäykset",
      eng: "Coloring",
      swe: "Färgningar",
    },
    url: color,
    path: "varjaykset",
  },
  {
    id: 2,
    title: {
      fin: "Kampaukset ja meikit",
      eng: "Updos and makeup",
      swe: "Frisyrer och sminkningar",
    },
    url: KampauksetJaMeikit,
    path: "kampauksetjameikit",
  },
  {
    id: 3,
    title: {
      fin: "Ripset ja kulmat",
      eng: "Eyebrows and lashes",
      swe: "Ögonbryn och fransar",
    },
    url: ripsetjakulmat,
    path: "ripsetjakulmat",
  },
  {
    id: 4,
    title: {
      fin: "Hiushoidot",
      eng: "Hair treatments",
      swe: "Hårbehandlingar",
    },
    url: muut,
    path: "hoidot",
  },
];
