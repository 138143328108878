import { Col, Container, Row, Image, Button, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SideNavServices } from "./sideNavServices";
import { kampauksetJaMeikit } from "../../prices/listOfPrices";
import "./individualServices.css";
import { AccordionComponent } from "../../accordion";
import Helmet from "react-helmet";
import homepagePic from "../../../media/images/homepagePic.png";
import headPic from "../../../media/images/kampauksetjameikit/top_pic.jpg";
import pic1 from "../../../media/images/kampauksetjameikit/pic1.jpg";
import pic2 from "../../../media/images/kampauksetjameikit/pic2.jpeg";
import { useEffect } from "react";
import ga from '../../../googleAnalytics';
import { CheckAllPrices } from "../../CheckAllPrices";

export const KampauksetJaMeikitPage = ({ language }) => {
  const navigate = useNavigate();
  const handleNavigate = (location, label) =>{
    ga.trackGAEvent('check_prices', 'individualServices_button', label);
    navigate(location)
  }

  const allServices = kampauksetJaMeikit.filter(
    (ser) => typeof ser.id === "number"
  );

  useEffect(()=>{
    ga.trackPageView();
  }, [])

  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Kampaukset ja meikit</title>
        <meta
          name="description"
          property="og:description"
          content="Meiltä löydät kampaukset ja meikit joka tilaisuuteen."
        />
        <meta property="og:title" content="Kampaukset ja meikit" />
        <meta
          property="og:url"
          content="www.helmihelsinki.fi/services/kampauksetjameikit"
        />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, Services, palvelut, Kampaukset, meikit, ihanat kampaukset, hienot kampaukset, parhaat kampaukset, upeat kampaukset, nutturakampaus, lettikampaus, hääkampaus, häät, juhlakampaus, festkammning, festfrisyr, uppsättning, håruppsättning, updo, bridsl updo, wedding, bride, kampaukset helsinki, häämeikki, brud makeup, brudsminkning, nuttura, hairupdo"
        />
      </Helmet>
      <Container className="container-page">
        <Row>
          <Col md={4}>
            <Image
              alt="back button"
              src="https://img.icons8.com/ios/344/circled-left-2.png"
              className="service-back-btn"
              onClick={() => handleNavigate("/services", 'return')}
            ></Image>
          </Col>
          <Col md={8} className="individual-service-title">
            <h1>
              {language === "fin" && "Kampaukset ja meikit"}
              {language === "eng" && "Updos and makeup"}
              {language === "swe" && "Frisyrer och smink"}
            </h1>
          </Col>
        </Row>
        <Row style={{ margin: "5vh 0" }}>
          <Col md={3} className="hiddePhone">
            <SideNavServices language={language} />
          </Col>
          <Col md={9}>
            <Row className="individual-service-info">
              <Col xs={12} lg={5}>
                <Image
                  alt="haircut"
                  className="individual-service-img"
                  src={headPic}
                ></Image>
              </Col>
              <Col
                style={{ fontSize: "1.2rem", liheHeight: "1.5rem" }}
                xs={12}
                lg={7}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <Col>
                    {language === "fin" && <div>Olivat juhlasi rennommat, juhlallisemmat tai häät meiltä löydät kampaus- ja meikkipalvelut joka tilaisuuteen. Rennompaan juhlaan pikakampaus voi olla kätevä sinulle, silloin teemme nopeat kiharat tai pikakampauksen ja olet valmis jo puolessa tunnissa. Jos sinulla on hienompi tilaisuus tai juhlat tiedossa sinun kannattaa varata aika juhla- tai kiharakampaukseen jolloin meillä on enemmän aikaa suunnitella ja toteuttaa toiveesi.  <br/><br/> Päivämeikki on hyvä jos haluat hieman kevyemmän meikin joka sopii hyvin myös päivätilaisuuteen. Juhlameikki on oiva valinta iltajuhliin tai kun haluat näyttävämmän lookin. <br/><br/> Hääpäivä on ainutlaatuinen päivä jolloin toteutamme kampaus- ja meikkitoiveesi. Häämeikki  ja hääkampaus suunnitellaan ja toteutetaan toiveidesi mukaan. Sinun on myös mahdollista tulla konsultaatioon tai koemeikkiin ja koekampaukseen ennen hääpäivääsi.</div>}
                    {language === "eng" && <div>Whether you are going to a casual, more festive or a wedding party, you can find updo and makeup services for every occasion from us. For a casual party the 30min quick updo or curls suits well. If you are going to a festive party book an appointment for a special occasion updo or curls service where we have more time to plan and style your hair according to your wishes. <br/><br/> The quick make up is good for a casual party. The special occasion make up is perfect if you are going to an evening or festive party. <br/><br/> The wedding day is a special day where we do your hair and makeup exactly as you wish. It's good to book a consultation appointment before the wedding day. A testmakeup and  testupdo can also be done before your wedding day if you wish so.  </div>}
                    {language === "swe" && <div>Är du på väg till en mindre eller större tillställning eller ett bröllop, så hittar du olika frisyr- och sminktjänster hos oss. För en mindre fest passar det med lockar eller en snabb uppsättning. För ett festligare tillfälle är det bra och boka festlig uppsättning eller lockning, då har vi mer tid att planera och utföra uppsättningen enligt dina önskemål. <br/><br/>
                    Dagssminkning passar bra för en mindre fest eller för en tillställning dagtid. Festsminkning passar bra om du är på väg till en större fest eller kvällstillställning. <br/><br/> Bröllopsdagen är en speciell dag då vi gör håruppsättningen och sminket enligt dina önskemål. Du kan boka en konsultation gällande håruppsättning och smink före bröllopsdagen. Vi kan också göra en testhåruppsättning eller testsminkning före bröllopsdagen ifall du så önskar. </div>}
                  </Col>
                  <Col>
                    <Row>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.facebook.com/HelmiHelsinki13/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={()=>ga.trackGAEvent('follow_button', 'individualServices_button', 'facebook')}
                          >
                            <Image
                              alt="Facebook"
                              src="https://img.icons8.com/ios/344/facebook-new.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Facebook
                          </Button>
                        </a>
                      </Col>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.instagram.com/helmihelsinki/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={()=>ga.trackGAEvent('follow_button', 'individualServices_button', 'instagram')}
                          >
                            <Image
                              alt="Instagram"
                              src="https://img.icons8.com/ios/344/instagram-new--v1.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Instagram
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="indService-pics">
          <Col xs={12} md={{offset:0 , span: 6}}>
            <Image
              alt="värjäykset"
              className="service-pic-list"
              src={pic1}
            ></Image>
          </Col>
          <Col xs={12} md={{offset:1 , span: 4}}>
            <Image
              alt="värjäykset"
              className="service-pic-list"
              src={pic2}
            ></Image>
          </Col>
        </Row>
        <Row>
          <Col className="flex-center-column">
            <h4>
              {language === "fin" && "Kampaus ja meikki palvelumme "}
              {language === "eng" && "Our updo and makeup services "}
              {language === "swe" && "Våra frisyr och smink tjänster"}
            </h4>
          </Col>
        </Row>
        <section style={{ padding: "5vh 10%" }}>
          <Accordion defaultActiveKey="0">
            {allServices.map((item) => (
              <AccordionComponent
                id={item.id}
                title={item.name}
                key={item.id}
                description={item.description}
                price={item.price}
                language={language}
              />
            ))}
          </Accordion>
        </section>
		<CheckAllPrices language={language} />
      </Container>
    </div>
  );
};
