import { Carousel, Container, Col, Row, Image, Button } from "react-bootstrap";
import { useState } from "react";
import "./imagesliderComponent.css";
import "../general.css";
import logo from "../../media/images/Helmihelsinki.png";

export const ImageSlider = ({ language }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Container className="container-page" id="slider">
      <Image
        alt="logo"
        src={logo}
        id="slide-topLogo"
        data-aos="fade-down"
        data-aos-delay="0"
        data-aos-duration="500"
        data-aos-mirror="false"
        data-aos-once="true"
      ></Image>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div id="carousel">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            className="carousel"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-mirror="false"
            data-aos-once="true"
          >
            {slideData.map((info, i) => {
              return (
                <Carousel.Item key={i}>
                  <img
                    className="d-block w-100 carousel-pic"
                    alt={info.id}
                    id={info.id}
                  />
                  {info.caption && (
                    <Carousel.Caption>
                      <div className="caption-info-box">
                        <h3>
                          {language === "fin" && <div>{info.title.fin}</div>}
                          {language === "eng" && <div>{info.title.eng}</div>}
                          {language === "swe" && <div>{info.title.swe}</div>}
                        </h3>
                        <Row>
                          <Col className="slide-description">
                            {language === "fin" && info.description.fin}
                            {language === "eng" && info.description.eng}
                            {language === "swe" && info.description.swe}
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Caption>
                  )}
                </Carousel.Item>
              );
            })}
            <Carousel.Item>
              <img
                className="d-block w-100 carousel-pic"
                alt="welcome to new worker"
                id="hanna-new-worker"
              />
              <Carousel.Caption>
                <Row>
                  <Col>
                    <Button
                      variant="secondary"
                      className="slider-hanna-book-btn"
                      onClick={() => {
                        window.location.href =
                          "https://varaa.timma.fi/tmihannaheikkila";
                      }}
                    >
                      {language === "fin" && "Varaa aikasi tästä"}
                      {language === "eng" && "Book your time here"}
                      {language === "swe" && "Boka din tid här"}
                    </Button>
                  </Col>
                </Row>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </Container>
  );
};

const slideData = [
  {
    id: "rekry",
    caption: false,
    title: {
      fin: "",
      eng: "",
      swe: "",
    },
    description: {
      fin: <div></div>,
      eng: <div></div>,
      swe: <div></div>,
    },
  },
  {
    id: "davines-products",
    caption: true,
    title: {
      fin: "Myynnissä meiltä löytyy Davines hiustuotteita",
      eng: "We sell Davines hairproducts",
      swe: "Vi säljer Davines hårvårdsprodukter",
    },
    description: {
      fin: (
        <div>Kestävästi kehitetty hiushoitosarja joka tuotetaan Italiassa.</div>
      ),
      eng: <div> Sustainable haircare brand made in Italy.</div>,
      swe: (
        <div>Hållbart utvecklad hårvårdsserie som tillverkas i Italien.</div>
      ),
    },
  },
  {
    id: "bjork-products",
    caption: true,
    title: {
      fin: "Myynnissä meillä löytyy Björk hiustuotteita",
      eng: "We sell Björk hairproducts",
      swe: "Vi säljer Björk hårvårdsprodukter",
    },
    description: {
      fin: (
        <div>
          Björk on vegaaninen, kestävästi kehitetty hiushoitosarja joka
          tuotetaan Ruotsissa.
        </div>
      ),
      eng: (
        <div>Björk is a sustainable, vegan haircare brand made in Sweden.</div>
      ),
      swe: (
        <div>
          Björk är en vegansk och hållbart utvecklad hårvårdsserie som
          tillverkas i Sverige.
        </div>
      ),
    },
  },
];
