import { Col, Container, Row, Image, Button, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SideNavServices } from "./sideNavServices";
import { leikkaukset } from "../../prices/listOfPrices";
import "./individualServices.css";
import { AccordionComponent } from "../../accordion";
import { Helmet } from "react-helmet";
import homepagePic from "../../../media/images/homepagePic.png";
import cut1 from "../../../media/images/Leikkaukset/cut1.jpeg";
import cut2 from "../../../media/images/Leikkaukset/cut2.jpg";
import cutHeadPic from '../../../media/images/Leikkaukset/top_page.jpg'
import { useEffect } from "react";
import ga from '../../../googleAnalytics';
import { CheckAllPrices } from "../../CheckAllPrices";

export const LeikkauksetPage = ({ language }) => {
  const navigate = useNavigate();
  const handleNavigate = (location, label) => {
    ga.trackGAEvent('follow_button', 'individualServices_button', label);
    navigate(location);
  }
  useEffect(()=>{
    ga.trackPageView();
  })
  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Leikkaukset</title>
        <meta
          name="description"
          property="og:description"
          content="Meiltä luonnistuu kaiken tyyliset leikkaukset jotka sopivat kaikille iästä ja tyylistä riippumatta, niin naisille kuin miehille. "
        />
        <meta property="og:title" content="Leikkaukset" />
        <meta
          property="og:url"
          content="www.helmihelsinki.fi/services/leikkaukset"
        />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, Services, palvelut, Leikkaukset, haircut, hair, cut, haircut, barber, barbering, kampaamo, kampaamoleikkaus, parturileikkaus, hiustenleikkaus, hiukset, paras kampaamo helsinki, paras kampaamo, lyhyet hiukset, pitkät hiukset, hår, hårklippning, klippning, barberare, parturi helsinki, kampaamo helsinki, frisyr, kampaus"
        />
      </Helmet>
      <Container className="container-page">
        <Row>
          <Col md={4}>
            <Image
              alt="back button"
              src="https://img.icons8.com/ios/344/circled-left-2.png"
              className="service-back-btn"
              onClick={() => handleNavigate("/services", 'return')}
            ></Image>
          </Col>
          <Col md={8} className="individual-service-title">
            <h1>
              {language === "fin" && "Leikkaukset"}
              {language === "eng" && "Haircut"}
              {language === "swe" && "Hårklippning"}
            </h1>
          </Col>
        </Row>
        <Row style={{ margin: "5vh 0" }}>
          <Col md={3} className="hiddePhone">
            <SideNavServices language={language} />
          </Col>
          <Col md={9}>
            <Row className="individual-service-info">
              <Col xs={12} lg={5}>
                <Image
                  alt="haircut"
                  className="individual-service-img"
                  src={cutHeadPic}
                ></Image>
              </Col>
              <Col
                style={{ fontSize: "1.2rem", liheHeight: "1.5rem" }}
                xs={12}
                lg={7}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <Col>
                    {language === 'fin' && 
                      <div>
                        Siististi leikatut hiukset näyttävät ja tuntuvat hyvälle. Toteutamme leikkaukset toiveitasi kuunnellen. Meiltä luonnistuu kaiken tyyliset leikkaukset jotka sopivat kaikille iästä ja tyylistä riippumatta, niin naisille kuin miehille.  
                      </div>}

                    {language === 'eng' && 
                      <div>
                        A nice haircut makes you look and feel better. At Helmi Helsinki you can get haircuts that fit for all ages and styles, both men and women.
                      </div>}
                    {language === 'swe' && 
                      <div>
                        Vi klipper frisyrer som passar alla åldrar och stilar, både män och kvinnor. 
                      </div>}
                  </Col>
                  <Col>
                    <Row>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.facebook.com/HelmiHelsinki13/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={()=>ga.trackGAEvent('follow_button', 'individualServices_button', 'facebook')}
                          >
                            <Image
                              alt="Facebook"
                              src="https://img.icons8.com/ios/344/facebook-new.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Facebook
                          </Button>
                        </a>
                      </Col>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.instagram.com/helmihelsinki/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={()=>ga.trackGAEvent('follow_button', 'individualServices_button', 'instagram')}
                          >
                            <Image
                              alt="Instagram"
                              src="https://img.icons8.com/ios/344/instagram-new--v1.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Instagram
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="indService-pics">
          <Col xs={12} md={{offset:2 , span: 4}}>
            <Image
              alt="leikkaukset"
              className="service-pic-list"
              src={cut1}
            ></Image>
          </Col>
          <Col xs={12} md={{offset:1 , span: 4}}>
            <Image
              alt="leikkaukset"
              className="service-pic-list"
              src={cut2}
            ></Image>
          </Col>
          
        </Row>
        <Row>
          <Col className="flex-center-column">
            <h4>
              {language === "fin" && "Leikkaus palvelumme"}
              {language === "eng" && "Haircut Services"}
              {language === "swe" && "Hårklippningar"}
            </h4>
          </Col>
        </Row>
        <section style={{ padding: "5vh 10%" }}>
          <Accordion defaultActiveKey="0">
            {leikkaukset.map((item) => (
              <AccordionComponent
                id={item.id}
                title={item.name}
                key={item.id}
                description={item.description}
                price={item.price}
                language={language}
              />
            ))}
          </Accordion>
        </section>
		<CheckAllPrices language={language} />
      </Container>
    </div>
  );
};
