import { Col, Container, Row, Image, Button, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SideNavServices } from "./sideNavServices";
import { värjäykset } from "../../prices/listOfPrices";
import "./individualServices.css";
import { AccordionComponent } from "../../accordion";
import Helmet from "react-helmet";
import homepagePic from "../../../media/images/homepagePic.png";
import headPic from "../../../media/images/varjaykset/top_page.jpeg";
import pic1 from "../../../media/images/varjaykset/pic1.jpg";
import pic2 from "../../../media/images/varjaykset/pic2.jpeg";
import { useEffect } from "react";
import ga from '../../../googleAnalytics';
import { CheckAllPrices } from "../../CheckAllPrices";

export const VärjäyksetPage = ({language}) => {
  const navigate = useNavigate();
  const handleNavigate = (location, label) => {
    ga.trackGAEvent('follow_button', 'individualServices_button', label);
    navigate(location)
  }

  const allServices = värjäykset.filter((ser) => typeof ser.id === "number");

  useEffect(()=>{
    ga.trackPageView();
  }, [])

  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Värjäykset</title>
        <meta
          name="description"
          property="og:description"
          content="Teemme monenlaisia eri värjäyksiä niin perinteisiä, trendikkäitä kuin erilaisia vaalennuksia. Täältä löydät meidän hiustenvärjäyspalvelumme."
        />
        <meta property="og:title" content="Värjäykset" />
        <meta
          property="og:url"
          content="www.helmihelsinki.fi/services/värjäykset"
        />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, Services, palvelut, Värjäykset, color, highlights, blonde, vaaleat hiukset, raidat, ombre, balayage, raidoitus, tupeeraus raidat, face framing, ränder, slingor, färgning, hårfärgning, hiusten värjäys, babylights, folioraidat, vapaankäden raidat, vaaleat hiukset, ljust hår, blondt hår, mörkt hår, teasylights"
        />
      </Helmet>
      <Container className="container-page">
        <Row>
          <Col md={4}>
            <Image
              alt="back button"
              src="https://img.icons8.com/ios/344/circled-left-2.png"
              className="service-back-btn"
              onClick={() => handleNavigate("/services", 'return')}
            ></Image>
          </Col>
          <Col
            md={8}
            className="individual-service-title"
          >
            <h1>
              {language==='fin' && "Värjäykset"}
              {language==='eng' && "Coloring"}
              {language==='swe' && "Färgningar"}
            </h1>
          </Col>
        </Row>
        <Row style={{ margin: "5vh 0" }}>
          <Col md={3} className="hiddePhone">
            <SideNavServices language={language}/>
          </Col>
          <Col md={9}>
            <Row className="individual-service-info">
              <Col xs={12} lg={5}>
                <Image
                  alt="haircut"
                  className="individual-service-img"
                  src={headPic}
                ></Image>
              </Col>
              <Col
                style={{ fontSize: "1.2rem", liheHeight: "1.5rem" }}
                xs={12}
                lg={7}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <Col>
                    {language === 'fin' && 
                      <div>
                        Teemme monenlaisia eri värjäyksiä niin perinteisiä, trendikkäitä kuin erilaisia vaalennuksia. Käyttämämme hiusvärit ovat vegaanisia. Kouluttaudumme myös jatkuvasti pysyäksemme mukana viimeisimmissä trendeissä. Jos vaalennetaan tummemmasta vaaleaan se vaatii usein useamman värjäys kerran. Erilaisiin raitoihin ja vaalennuksiin on olemassa eri tekniikoita, joita toteutetaan halutun lopputuloksen mukaan.
                      </div>}
                    {language === 'eng' && <div> We do different kind of colorings, natural ones, trendy and different kind of highlights. All our haircolors are vegan. We are also educating ourselves to stay on board with the newest trends. If we lighten the hair from darker to blonde that usually requires several appointments to get the wanted result. There are different kind of highlight techniques that can be used, depending on what the wanted result is.
                      </div>}
                    {language === 'swe' && 
                      <div>
                        Vi gör olika slags färgningar, mer naturliga, trendiga och olika slags blekningar. Alla våra hårfärger år veganska. Vi håller oss även uppdaterade om det nyaste inom hårtrender. Ifall vi bleker håret från mörkare till ljusare, så behövs det oftast flera besök för att oppnå önskat resultat. Till slingor och blonda färgningar finns olika slags tekniker, som vi verkställer så att önskat resultat uppnås.
                      </div>}
                  </Col>
                  <Col>
                    <Row>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.facebook.com/HelmiHelsinki13/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={()=>ga.trackGAEvent('follow_button', 'individualServices_button', 'facebook')}
                          >
                            <Image
                              alt="Facebook"
                              src="https://img.icons8.com/ios/344/facebook-new.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Facebook
                          </Button>
                        </a>
                      </Col>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.instagram.com/helmihelsinki/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={()=>ga.trackGAEvent('follow_button', 'individualServices_button', 'instagram')}
                          >
                            <Image
                              alt="Instagram"
                              src="https://img.icons8.com/ios/344/instagram-new--v1.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Instagram
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="indService-pics">
          <Col xs={12} md={{offset:1 , span: 4}}>
            <Image
              alt="värjäykset"
              className="service-pic-list"
              src={pic1}
            ></Image>
          </Col>
          <Col xs={12} md={{offset:1 , span: 4}}>
            <Image
              alt="värjäykset"
              className="service-pic-list"
              src={pic2}
            ></Image>
          </Col>
        </Row>
        <Row>
          <Col className="flex-center-column">
            <h4>
              {language === 'fin' && "Värjäys palvelumme"}
              {language === 'eng' && "Our coloring services"}
              {language === 'swe' && "Våra färgbehandlingar"}
            </h4>
          </Col>
        </Row>
        <section style={{ padding: "5vh 10%" }}>
          <Accordion defaultActiveKey="0">
            {allServices.map((item) => (
              <AccordionComponent
                id={item.id}
                title={item.name}
                key={item.id}
                description={item.description}
                price={item.price}
                language={language}
              />
            ))}
          </Accordion>
        </section>
		<CheckAllPrices language={language} />
      </Container>
    </div>
  );
};
