import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { NavbarComponent } from "./components/navbar/navbarComponent";
import { Footer } from "./components/footer/footer";
import { BookTime } from "./components/bookTime/booktime";
import { Homepage } from "./components/homepage/homepage";
import { ContactsComponent } from "./components/Contacts/contacts";
import { ServicePage } from "./components/servicePage/servicePage";
import { MeetUs } from "./components/meetUs/meetUs";
import { PricesListComponent } from "./components/prices/pricesComponent";
import ScrollToTop from "./components/scrollToTopWrapper/scrollToTopWrapper";
import { GalleryComponent } from "./components/gallery/gallery";
import { LeikkauksetPage } from "./components/servicePage/individualServicesPage/leikkaukset";
import { VärjäyksetPage } from "./components/servicePage/individualServicesPage/värjäykset";
import { KampauksetJaMeikitPage } from "./components/servicePage/individualServicesPage/KampauksetJaMeikit";
import { RipsetJaKulmatPage } from "./components/servicePage/individualServicesPage/RipsetJaKulmat";
import { MuutPage } from "./components/servicePage/individualServicesPage/Muut";
import { NewProducts } from "./components/new-products/new-products";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactGA from "react-ga4";

function App() {
  const [language, setLanguage] = useState(
    Cookies.get("language") ? Cookies.get("language") : "fin"
  );
  AOS.init({
    disable: "desktop",
  });
  const allowCookies = getCookieConsentValue();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_Analytics, {
      send_page_view: false,
    });

    //report to page view
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta property="og:title" content="Helmi Helsinki" />
        <meta property="og:url" content="www.helmihelsinki.fi" />
        <meta property="og:image" content="https://ibb.co/N6Ry5ty" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:title" content="Helmi Helsinki" />
        <meta
          property="og:description"
          content="Helmi Helsingistä löydät laajan valikoiman palveluja niin naisille kuin miehille. Meiltä löydät parturi-, kampaamo-, kampaus-, meikki- sekä kynsipalveluita"
        />
      </Helmet>
      <BrowserRouter>
        <NavbarComponent
          setLanguage={setLanguage}
          language={language}
          allowCookies={allowCookies}
        />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Homepage language={language} />}></Route>
            <Route path="/booking" element={<BookTime />}></Route>
            <Route
              path="/contacts"
              element={<ContactsComponent language={language} />}
            ></Route>
            <Route path="services">
              <Route
                index={true}
                element={<ServicePage language={language} />}
              ></Route>
              <Route
                path="leikkaukset"
                element={<LeikkauksetPage language={language} />}
              ></Route>
              <Route
                path="varjaykset"
                element={<VärjäyksetPage language={language} />}
              ></Route>
              <Route
                path="kampauksetjameikit"
                element={<KampauksetJaMeikitPage language={language} />}
              ></Route>
              <Route
                path="ripsetjakulmat"
                element={<RipsetJaKulmatPage language={language} />}
              ></Route>
              <Route
                path="hoidot"
                element={<MuutPage language={language} />}
              ></Route>
            </Route>
            <Route
              path="/meetUs"
              element={<MeetUs language={language} />}
            ></Route>
            <Route
              path="/gallery"
              element={<GalleryComponent language={language} />}
            ></Route>
            <Route
              path="/prices"
              element={<PricesListComponent language={language} />}
            ></Route>
            <Route
              path="/uutuudet"
              element={<NewProducts language={language} />}
            ></Route>
            <Route path="*" element={<Homepage language={language} />}></Route>
          </Routes>
        </ScrollToTop>
        <Footer language={language} />
        <CookieConsent
          buttonText="Agree!"
          enableDeclineButton
          declineButtonText="I disagree!"
          expires={31}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </BrowserRouter>
    </div>
  );
}

export default App;
