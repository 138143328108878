import { Col, Container, Row } from "react-bootstrap";
import "./pricesComponent.css";
import { leikkaukset } from "./listOfPrices";
import { värjäykset } from "./listOfPrices";
import { kampauksetJaMeikit } from "./listOfPrices";
import { ripsetJaKulmat } from "./listOfPrices";
import { hoidot } from "./listOfPrices";
import Helmet from "react-helmet";
import homepagePic from "../../media/images/homepagePic.png";
import { listOfServices } from "../servicePage/listOfServices";
import ga from "../../googleAnalytics";
import { useEffect } from "react";

export const PricesListComponent = ({ language }) => {
  useEffect(() => {
    ga.trackPageView();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Hinnasto</title>
        <meta
          name="description"
          property="og:description"
          content="Täältä löydät Helmi Helsingin palvelut ja hinnaston"
        />
        <meta property="og:title" content="Hinnasto" />
        <meta property="og:url" content="www.helmihelsinki.fi/prices" />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, hinnasto, prices, hinnat, pris, priser, frisörpriser, kampaamohinnat, parturihinnat, kynnet, kynsien hinnat, barberarpriser"
        />
      </Helmet>
      <Container className="container-page">
        <Row>
          <Col
            style={{
              textAlign: "center",
              marginTop: "4vh",
              letterSpacing: "1vh",
            }}
          >
            <h1>
              {language === "fin" && "Hinnasto"}
              {language === "eng" && "Pricelist"}
              {language === "swe" && "Prislista"}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              textAlign: "center",
              marginBottom: "10vh",
              letterSpacing: "0.5vh",
            }}
          >
            {language === "fin" && "Täältä löydät kaikkien palvelumme hinnat"}
            {language === "eng" && "Find the prices for all our services here"}
            {language === "swe" && "Här hittar du alla våra tjänsters priser"}
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Row className="prices-groups">
              <Col className="service-price-title">
                <h3>
                  {language === "fin" && listOfServices[0].title.fin}
                  {language === "eng" && listOfServices[0].title.eng}
                  {language === "swe" && listOfServices[0].title.swe}
                </h3>
              </Col>
              {leikkaukset.map((price) => (
                <Row className="service-price-description" key={price.id}>
                  <Col xs={8}>
                    {language === "fin" && price.name.fin}
                    {language === "eng" && price.name.eng}
                    {language === "swe" && price.name.swe}
                  </Col>
                  <Col xs={4}>{price.price}</Col>
                </Row>
              ))}
            </Row>
            <Row className="prices-groups">
              <Col className="service-price-title">
                <h3>
                  {language === "fin" && listOfServices[1].title.fin}
                  {language === "eng" && listOfServices[1].title.eng}
                  {language === "swe" && listOfServices[1].title.swe}
                </h3>
              </Col>
              {värjäykset.map((price) => (
                <Row className="service-price-description" key={price.id}>
                  <Col xs={8}>
                    {language === "fin" && price.name.fin}
                    {language === "eng" && price.name.eng}
                    {language === "swe" && price.name.swe}
                  </Col>
                  <Col xs={4}>{price.price}</Col>
                </Row>
              ))}
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Row className="prices-groups">
              <Col className="service-price-title">
                <h3>
                  {language === "fin" && listOfServices[2].title.fin}
                  {language === "eng" && listOfServices[2].title.eng}
                  {language === "swe" && listOfServices[2].title.swe}
                </h3>
              </Col>
              {kampauksetJaMeikit.map((price) => (
                <Row className="service-price-description" key={price.id}>
                  <Col xs={8}>
                    {language === "fin" && price.name.fin}
                    {language === "eng" && price.name.eng}
                    {language === "swe" && price.name.swe}
                  </Col>
                  <Col xs={4}>{price.price}</Col>
                </Row>
              ))}
            </Row>
            <Row className="prices-groups">
              <Col className="service-price-title">
                <h3>
                  {language === "fin" && listOfServices[3].title.fin}
                  {language === "eng" && listOfServices[3].title.eng}
                  {language === "swe" && listOfServices[3].title.swe}
                </h3>
              </Col>
              {ripsetJaKulmat.map((price) => (
                <Row className="service-price-description" key={price.id}>
                  <Col xs={8}>{price.name.fin}</Col>
                  <Col xs={4}>{price.price}</Col>
                </Row>
              ))}
            </Row>
            <Row className="prices-groups">
              <Col className="service-price-title">
                <h3>
                  {language === "fin" && listOfServices[4].title.fin}
                  {language === "eng" && listOfServices[4].title.eng}
                  {language === "swe" && listOfServices[4].title.swe}
                </h3>
              </Col>
              {hoidot.map((price) => (
                <Row className="service-price-description" key={price.id}>
                  <Col xs={8}>
                    {language === "fin" && price.name.fin}
                    {language === "eng" && price.name.eng}
                    {language === "swe" && price.name.swe}
                  </Col>
                  <Col xs={4}>{price.price}</Col>
                </Row>
              ))}
            </Row>
            <Row>
              <Col className="service-price-title">
                {language === "fin" && (
                  <div>
                    Hinnat perustuvat työhön varattuun aikaan, käytettyihin
                    tuotteisiin ja niiden määrään sekä työn ammatilliseen
                    vaativuuteen. Hinnastossa mainittu työn suorittamiseksi
                    kuluva aika saattaa vaihdella hiusten paksuuden ja pituuden
                    mukaan.
                  </div>
                )}
                {language === "eng" && (
                  <div>
                    The prices are based on the time, the products used and
                    their quantity, and the professional complexity of the work.
                    The time required to complete the work mentioned in the
                    pricelist may vary depending on the thickness and length of
                    the hair.
                  </div>
                )}
                {language === "swe" && (
                  <div>
                    Priserna baserar sig på tiden, produkter som använts och
                    deras mängd samt på hur tekniskt krävande arbetet är. Den
                    tid som krävs för att slutföra arbetet som nämns i
                    prislistan kan variera beroende på hårets tjocklek och
                    längd.
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
