export const leikkaukset = [
  {
    name: {
      fin: "Kampaamoleikkaus sis. pesu (60min)",
      eng: "Ladies haircut (60min)",
      swe: "Damklippning(60min)",
    },
    price: "70€",
    page: "Kampaamoleikkaus",
    id: 1,
    description: {
      fin: "Hiusten siistiminen tai samaan malliin leikkaus. Sisältää pesun ja föönauksen.",
      eng: "Trimming the hair or cutting to the same style. Includes washing ans blowdry.",
      swe: "Klippning till samma modell. Tvätt och föning ingår.",
    },
  },
  {
    name: {
      fin: "Parturileikkaus sis. pesu (45min)",
      eng: "Men's haircut, includes wash (45min)",
      swe: "Herrklippning, tvätt ingår (45min)",
    },
    price: "40€",
    page: "Parturileikkaus",
    id: 2,
    description: {
      fin: "Lyhyiden hiusten leikkaus tai siistiminen. Sisältää pesun.",
      eng: "Haircut for short hair, same style, includes washing.",
      swe: "Herrklippning, samma modell, tvätt ingår.",
    },
  },
  {
    name: {
      fin: "Tasaus (30min)",
      eng: "Trimming the ends (30min)",
      swe: "Klippning av toppar (30min)",
    },
    price: "40€",
    page: "Tasaus",
    id: 3,
    description: {
      fin: "Pitkien hiusten latvojen tasaus 30min ei sis. pesua.",
      eng: "Trimming the ends on long hair, does not include wash.",
      swe: "Klippning av toppar på långt hår, tvätt ingår inte.",
    },
  },
  {
    name: {
      fin: "Koneajo (15min)",
      eng: "Buzzcut (15min)",
      swe: "Hårklippning med maskin (15min)",
    },
    price: "25€",
    page: "Koneajo",
    id: 4,
    description: {
      fin: "Hiusten ajo koneella.",
      eng: "Buzzcut",
      swe: "Klippning med maskin.",
    },
  },
  {
    name: {
      fin: "Otsatukan leikkaus (15min)",
      eng: "Bangs trim (15min)",
      swe: "Luggklippning (15min)",
    },
    price: "25€",
    page: "Otsatukan",
    id: 5,
    description: {
      fin: "Otsatukan leikkaus.",
      eng: "Bangs trim.",
      swe: "Luggklippning.",
    },
  },
  {
    name: {
      fin: "Mallinmuutosleikkaus (75min)",
      eng: "New hairstyle (75min)",
      swe: "Ny frisyr (75min)",
    },
    price: "82€",
    page: "Mallinmuutosleikkaus",
    id: 6,
    description: {
      fin: "Hiusten leikkaus uuteen malliin. Sisältää pesun ja föönauksen.",
      eng: "Haircut into new style. Includes washing and blowdry.",
      swe: "Klippning till ny frisyr. Tvätt och föning ingår.",
    },
  },
  {
    name: {
      fin: "Parturileikkaus mallinmuutos (60min)",
      eng: "Men's haircut new style (60min)",
      swe: "Herrfrisering ny frisyr (60min)",
    },
    price: "52€",
    page: "Parturileikkaus-mallinmuutos",
    id: 7,
    description: {
      fin: "Parturileikkaus vaativampi malli/ pitkät hiukset. Sisältää pesun.",
      eng: "New style, longer hair/ long hair. Includes wash.",
      swe: "Ny frisyr, längre hår/långt hår. Tvätt ingår.",
    },
  },
  {
    name: {
      fin: "Lasten hiustenleikkaus 0-11v (30min)",
      eng: "Children's haircut 0-11years (30min)",
      swe: "Barnklippning 0-11år (30min)",
    },
    price: "30€",
    page: "Lasten-hiustenleikkaus-0-11v",
    id: 8,
    description: {
      fin: "Lasten hiustenleikkaus. Sisältää pesun.",
      eng: "Children's haircut. Includes wash.",
      swe: "Barnklippning. Tvätt ingår.",
    },
  },
];

export const värjäykset = [
  {
    name: {
      fin: "Tyviväri enintään 3cm. (105min)",
      eng: "Color (Roots) max. 3cm (105min)",
      swe: "Utväxtfärgning max. 3cm (105min)",
    },
    price: "95€",
    page: "Tyviväri-enintään-3cm",
    id: 1,
    description: {
      fin: "Tyviväri (enintään 3 cm). Ei koske esivaalennusta vaativia värjäyksiä. Sisältää pesun.",
      eng: "Coloring of roots. Doesn't include color that requires pre-bleaching.",
      swe: "Rotfärgning (max 3cm). Gäller ej färgningar som kräver förblekning.",
    },
  },
  {
    name: {
      fin: "Tyviväri ja leikkaus, tyviväri enintään 3cm. (120min) ",
      eng: "Color & Cut (Roots) (120min)",
      swe: "Utväxtfärgning och klippning (120min)",
    },
    price: "120€",
    page: "Tyviväri-ja-leikkaus-tyviväri-enintään-3cm",
    id: 2,
    description: {
      fin: "Tyviväri (enintään 3 cm) ja leikkaus. Ei koske esivaalennusta vaativia värjäyksiä. Sisältää pesun.",
      eng: "Coloring of roots and cut. Doesn't include color that requires pre-bleaching.",
      swe: "Rotfärgning (max 3cm) och klippning. Gäller ej färgningar som kräver förblekning. Innehåller tvätt.",
    },
  },
  {
    name: {
      fin: "Väri ja leikkaus (lyhyet) 1 väri (120min)",
      eng: "Color & Cut (Short) (120min)",
      swe: "Färgning och klippning (kort) (120min)",
    },
    price: "129€",
    page: "Väri-ja-leikkaus-lyhyet-1-väri",
    id: 3,
    description: {
      fin: "Hiusten värjäys ja leikkaus (1-väri), niskahiukset leukalinjan yläpuolella. Sisältää pesun.",
      eng: "Color & Cut (Short) - 1 color.",
      swe: "Färgning och klippning (1 färg), hår ovanför käklinjen. Innehåller tvätt.",
    },
  },
  {
    name: {
      fin: "Väri ja leikkaus (keskipitkät) 1 väri (135min)",
      eng: "Color & Cut (Medium) (135min)",
      swe: "Färgning och klippning (mellanlångt) (135min)",
    },
    price: "139€",
    page: "Väri-ja-leikkaus-keskipitkät-1-väri",
    id: 4,
    description: {
      fin: "Hiusten värjäys ja leikkaus (1-väri), hiukset olkapäiden yläpuolella. Sisältää pesun.",
      eng: "Color & Cut (medium) - 1 color",
      swe: "Färgning och klippning (1 färg), hår ovanför axlar. Innehåller tvätt.",
    },
  },
  {
    name: {
      fin: "Väri ja leikkaus (pitkät) 1 väri (150min)",
      eng: "Color & Cut (Long) (150min)",
      swe: "Färgning och klippning (långt) (150min)",
    },
    price: "149€",
    page: "Väri-ja-leikkaus-pitkät-1-väri",
    id: 5,
    description: {
      fin: "Hiusten värjäys ja leikkaus (1-väri), hiukset lapaluiden yläpuolella. Sisältää pesun.",
      eng: "Color & Cut (long) - 1 color.",
      swe: "Färgning och klippning (1-färg), hår ovanför skulderbladen. Innehåller tvätt.",
    },
  },
  {
    name: {
      fin: "Väri ja leikkaus (extrapitkät) 1 väri (165min) ",
      eng: "Color & Cut (Extra Long) (165min)",
      swe: "Färgning och klippning (extra långt) (165min)",
    },
    price: "159€",
    page: "Väri-ja-leikkaus-extrapitkät-1-väri",
    id: 6,
    description: {
      fin: "Hiusten värjäys ja leikkaus (1-väri) hiukset lapaluiden alapuolella. Sisältää pesun.",
      eng: "Color & Cut (Extra Long) - 1 color.",
      swe: "Färgning och klippning (1-färg), hår under skulderbladen. Innehåller tvätt.",
    },
  },
  {
    name: <br />,
    price: "",
    page: "",
    id: "br1",
  },
  {
    name: {
      fin: "Raidat ja leikkaus (lyhyet) (150min)",
      eng: "Highlights & Cut (Short) (150min)",
      swe: "Slingor och klippning (kort) (150min)",
    },
    price: "145€",
    page: "Raidat-ja-leikkaus-lyhyet",
    id: 7,
    description: {
      fin: "Raidat ja leikkaus, niskahiukset leukalinjan yläpuolella. Sisältää pesun.",
      eng: "Highlights & Cut (Short)",
      swe: "Slingor och klippning, hår ovanför käklinjen. Tvätt ingår.",
    },
  },
  {
    name: {
      fin: "Raidat ja leikkaus (keskipitkät) (180min)",
      eng: "Highlights & Cut (Medium) (180min)",
      swe: "Slingor och klippning (mellanlångt) (180min)",
    },
    price: "156€",
    page: "Raidat-ja-leikkaus-keskipitkät",
    id: 8,
    description: {
      fin: "Raidat ja leikkaus, hiukset olkapäiden yläpuolella. Sisältää pesun.",
      eng: "Highlights & Cut (medium)",
      swe: "Slingor och klippning, hår ovanför axlarna. Tvätt ingår",
    },
  },
  {
    name: {
      fin: "Raidat ja leikkaus (pitkät) (210min)",
      eng: "Highlights & Cut (Long) (210min)",
      swe: "Slingor och klippning (långt) (210min)",
    },
    price: "165€",
    page: "Raidat-ja-leikkaus-pitkät",
    id: 9,
    description: {
      fin: "Raidat ja leikkaus, hiukset lapaluiden yläpuolella. Sisältää pesun.",
      eng: "Highlights & Cut (Long)",
      swe: "Slingor och klippning, hår under skulderbladen. Tvätt ingår",
    },
  },
  {
    name: {
      fin: "Raidat ja leikkaus (extrapitkät) (210min)",
      eng: "Highlights & Cut (Extra Long) (210min)",
      swe: "Slingor och klippning (extra långt) (210min)",
    },
    price: "175€",
    page: "Raidat-ja-leikkaus-extrapitkät",
    id: 10,
    description: {
      fin: "Raidat ja leikkaus, hiukset lapaluiden alapuolella. Sisältää pesun.",
      eng: "Highlights & Cut (Extra Long)",
      swe: "Slingor och klippning, hår under skulderbladen. Tvätt ingår.",
    },
  },
  {
    name: <br />,
    price: "",
    page: "",
    id: "br2",
  },
  {
    name: {
      fin: "Moniväri ja leikkaus (lyhtet) (150min)",
      eng: "Multicolor & Cut (Short) (150min)",
      swe: "Flerfärg och klippning (kort) (150min)",
    },
    price: "145€",
    page: "Moniväri-ja-leikkaus-lyhtet",
    id: 11,
    description: {
      fin: "Monivärivärjäys ja leikkaus, niskahiukset leukalinjan yläpuolella. Sisältää pesun.",
      eng: "Multicolor & Cut (Short)",
      swe: "Flerfärg och klippning, hår ovanför käklinjen. Tvätt ingår",
    },
  },
  {
    name: {
      fin: "Moniväri ja leikkaus (keskipitkät) (180min)",
      eng: "Multicolor & Cut (Medium) (180min)",
      swe: "Flerfärg och klippning (mellanlångt) (180min)",
    },
    price: "156€",
    page: "Moniväri-ja-leikkaus-keskipitkät",
    id: 12,
    description: {
      fin: "Monivärivärjäys ja leikkaus, hiukset olkapäiden yläpuolella. Sisältää pesun.",
      eng: "Multicolor & Cut (Medium)",
      swe: "Flerfärg och klippning, hår ovanför axlarna. Tvätt ingår.",
    },
  },
  {
    name: {
      fin: "Moniväri ja leikkaus (pitkät) (210min) ",
      eng: "Multicolor & Cut (Long) (210min)",
      swe: "Flerfärg och klippning (långt) (210min)",
    },
    price: "175€",
    page: "Moniväri-ja-leikkaus-pitkät",
    id: 13,
    description: {
      fin: "Monivärivärjäys ja leikkaus, hiukset lapaluiden yläpuolella. Sisältää pesun.",
      eng: "Multicolor & Cut (Long)",
      swe: "Flerfärg och klippning, hår ovanför skulderbladen. Tvätt ingår.",
    },
  },
  {
    name: {
      fin: "Moniväri ja leikkaus (extrapitkät) (210min)",
      eng: "Multicolor & Cut (Extra Long) (210min)",
      swe: "Flerfärg och klippning (extra långt) (210min)",
    },
    price: "189€",
    page: "Moniväri-ja-leikkaus-extrapitkät",
    id: 14,
    description: {
      fin: "Monivärivärjäys ja leikkaus, hiukset lapaluiden alapuolella. Sisältää pesun.",
      eng: "Multicolor & Cut (Extra Long)",
      swe: "Flerfärg och klippning, hår under skulderbladen. Tvätt ingår.",
    },
  },
  {
    name: <br />,
    price: "",
    page: "",
    id: "br3",
  },
  {
    name: {
      fin: "Värinpoisto / esivaalennus/ color remover värjäyksen yhteydessä",
      eng: "Bleaching before color",
      swe: "Blekning före färgning",
    },
    price: "80-150€",
    page: "Värinpoisto",
    id: 15,
    description: {
      fin: "Jos haluttu väri vaatii esivaalennuksen, teemme ensin esivaalennuksen ja laitamme halutun värin sen jälkeen.",
      eng: "If the wished color needs bleaching before, we apply that first and then apply the final color.",
      swe: "Om färgen som önskas behöver blekning under, gör vi det först och applicerar sedan önskad färg.",
    },
  },
];

export const kampauksetJaMeikit = [
  {
    name: {
      fin: "Pikakampaus (45min)",
      eng: "Quick Hairdo (45min)",
      swe: "Snabb uppsättning (45min)",
    },
    price: "45€",
    page: "Pikakampaus",
    id: 1,
    description: {
      fin: "Pikakampaus piristää päivääsi tai viimeistelee bilelookisi!",
      eng: "Quick Hairdo that finishes your party style!",
      swe: "Snabb uppsättning för att piffa upp din dag eller slutföra festlooken!",
    },
  },
  {
    name: {
      fin: "Pikakampaus kihara (45min)",
      eng: "Quick hairdo curls (45min)",
      swe: "Snabb frisyr lockar(45min)",
    },
    price: "45€",
    page: "Pikakampaus",
    id: 2,
    description: {
      fin: "Pikakampaus, kiharat tehdään raudalla ja piristää päivääsi tai viimeistelee bilelookisi!",
      eng: "Quick hairdo done with curling wand, that finishes your party style!",
      swe: "Snabb frisyr, görs med locktång. Piffar upp din dag eller slutför partylooken!",
    },
  },
  {
    name: {
      fin: "Hiustenpesu & föönaus (45min) ",
      eng: "Wash & Blowdry (45min)",
      swe: "Tvätt och föning (45min)",
    },
    price: "40€",
    page: "Hiustenpesu-föönaus",
    id: 3,
    description: {
      fin: "Hiusten pesu ja föönaus lyhyet hiukset",
      eng: "Wash & blowdry, short hair",
      swe: "Tvätt och föning, kort hår",
    },
  },
  {
    name: {
      fin: "Hiustenpesu & föönaus (pitkät hiukset) (60min) ",
      eng: "Wash & Blowdry (long hair) (60min)",
      swe: "Tvätt och föning (långt hår) (60min)",
    },
    price: "60€",
    page: "Hiustenpesu-föönaus-pitkät-hiukset",
    id: 4,
    description: {
      fin: "Hiusten pesu ja föönaus, pitkät hiukset 30cm",
      eng: "Wash and blowdry, long hair 30cm",
      swe: "Tvätt och föning, långt hår 30cm",
    },
  },
  {
    name: {
      fin: "Kiharakampaus (60min) ",
      eng: "Curls (60min)",
      swe: "Lockning (60min)",
    },
    price: "60€",
    page: "Kiharakampaus",
    id: 5,
    description: {
      fin: "Suoristusraudalla tai kihartimella tehtävä auki oleva kiharakampaus",
      eng: "Curls done with the straightening iron or an curling iron",
      swe: "LÖppet hår gjorda med plattång eller locktång",
    },
  },
  {
    name: {
      fin: "Juhlakampaus (90min) ",
      eng: "Updo (90min)",
      swe: "Festlig uppsättning (90min)",
    },
    price: "90€",
    page: "Juhlakampaus",
    id: 6,
    description: {
      fin: "Tyylikäs juhlakampaus kruunaa lookisi!",
      eng: "Updo for a party or special occasion!",
      swe: "Festlig uppsättning toppar din look!",
    },
  },
  {
    name: {
      fin: "Hääkampaus (120min)",
      eng: "Bridal updo (120min)",
      swe: "Bröllopsuppsättning (120min)",
    },
    price: "139€",
    page: "Hääkampaus",
    id: 7,
    description: {
      fin: "Tyylikäs hääkampaus suunnuitellaan ja toteutetaan toiveidesi mukaan.",
      eng: "Bridal updo that is planned and done according to your wishes.",
      swe: "Bröllopsuppsättning som planeras och görs enligt dina önskemål.",
    },
  },
  {
    name: <br />,
    price: "",
    page: "",
    id: "br1",
  },
  {
    name: {
      fin: "Päivämeikki (45min)",
      eng: "Quick makeup (45min)",
      swe: "Dagssminkning (45min)",
    },
    price: "49€",
    page: "Päivämeikki",
    id: 8,
    description: {
      fin: "Kevyt päivämeikki",
      eng: "Light makeup",
      swe: "Lätt dag sminkning",
    },
  },
  {
    name: {
      fin: "Juhlameikki (90min)",
      eng: "Party makeup (90min)",
      swe: "Festlig sminkning (90min)",
    },
    price: "69€",
    page: "Juhlameikki",
    id: 9,
    description: {
      fin: "Juhlameikki, säteilevänä juhliisi!",
      eng: "Party makeup, shine at the party!",
      swe: "Festlig sminkning, bli strålande inför festen! ",
    },
  },
  {
    name: {
      fin: "Häämeikki (120min)",
      eng: "Wedding makeup (120min)",
      swe: "Bröllopssminkning (120min)",
    },
    price: "139€",
    page: "Häämeikki",
    id: 10,
    description: {
      fin: "Häämeikki. ",
      eng: "Wedding makeup",
      swe: "Bröllopssminkning",
    },
  },
];

export const ripsetJaKulmat = [
  {
    name: {
      fin: "Kulmien värjäys (15min)",
      eng: "Eyebrow coloring (15min)",
      swe: "Färgning av ögonbryn (15min)",
    },
    price: "10€",
    page: "Kulmien-värjäys",
    id: 1,
    description: {
      fin: "Kulmien värjäys kestovärillä.",
      eng: "Eyebrow coloring.",
      swe: "Färgning av ögonbryn.",
    },
  },
  {
    name: {
      fin: "Kulmien muotoilu (15min)",
      eng: "Shaping of eyebrows (15min)",
      swe: "Formning av ögonbryn (15min)",
    },
    price: "10€",
    page: "Kulmien-muotoilu",
    id: 2,
    description: {
      fin: "Kulmien muotoilu pinseteillä.",
      eng: "Shaping the eyebrows with a tweeser.",
      swe: "Formning av ögonbryn med pincett.",
    },
  },
  {
    name: {
      fin: "Kulmien värjäys ja muotoilu (30min)",
      eng: "Eyebrow shaping & tint (30min)",
      swe: "Färgning och formning av ögonbryn (30min)",
    },
    price: "20€",
    page: "Kulmien-värjäys-ja-muotoilu",
    id: 3,
    description: {
      fin: "Kulmien muotoilu ja värjäys kestovärillä. Kulmat muotoillaan pinseteillä.",
      eng: "Eyebrow shaping and tint. The shaping is done with tweesers.",
      swe: "Färgning och formning av ögonbryn. Formningen görs med pincett.",
    },
  },
  {
    name: {
      fin: "Ripsien värjäys (30min)",
      eng: "Lash tint (30min)",
      swe: "Färgning av ögonfransar (30min)",
    },
    price: "15€",
    page: "Ripsien-värjäys",
    id: 4,
    description: {
      fin: "Ripsien värjäys kestovärillä.",
      eng: "Coloring of lashes.",
      swe: "Färgning av ögonfransar.",
    },
  },
  {
    name: {
      fin: "Ripsien ja kulmien värjäys ja muotoilu (30min) ",
      eng: "Lash tint and eyebrow shaping and tint (30min)",
      swe: "Färgning av fransar och ögonbryn, samt formning av ögonbryn (30min)",
    },
    price: "31€",
    page: "Ripsien-ja-kulmien-värjäys-ja-muotoilu",
    id: 5,
    description: {
      fin: "Ripsien ja kulmien värjäys kestovärillä sekä kulmien muotoilu pinseteillä.",
      eng: "Lash tint and eyebrow shaping and tint. The shaping of eyebrows is done with tweesers.",
      swe: "Färgning av fransar och ögonbryn, samt formning av ögonbryn med pincett.",
    },
  },
];

export const hoidot = [
  {
    name: {
      fin: "Tuntiveloitus",
      eng: "Cost/hour",
      swe: "Pris/timme",
    },
    price: "70€",
    page: "Tuntiveloitus",
    id: 1,
    description: {
      fin: "Hiustenpidennykset, vaativampi hiustenvärjäystyö.",
      eng: "Hair extensions, more demanding haircolor.",
      swe: "Hårförlängningar, mera krävande färgning.",
    },
  },
  {
    name: {
      fin: "Tehohoito (15min) ",
      eng: "Intensive treatment",
      swe: "Vårdande inpackning",
    },
    price: "30€",
    page: "Tehohoito-leikkauksen-tai-värin-yhteydessä",
    id: 2,
    description: {
      fin: "Leikkauksen tai värin yhteydessä tehtävä kosteuttava tehohoito.",
      eng: "Intensive treatment to moisturize the hair. Can be applied when coloring or cutting the hair.",
      swe: "Vårdande inpackning som görs i samband med färgning eller klippning.",
    },
  },
  {
    name: {
      fin: "Brazilian Bond Builder ",
      eng: "Brazilian Bond Builder",
      swe: "Brazilian Bond Builder",
    },
    price: "20-70€",
    page: "Brazilian Bond Builder",
    id: 3,
    description: {
      fin: "Värin tai vaalennuksen yhteydessä käytettävä hiuksia suojaava ja korjaava hoito.",
      eng: "Repairing and protecting the hair whilst coloring or bleaching the hair.",
      swe: "Skyddar och återuppbygger håret när du färgar eller bleker det.",
    },
  },
  {
    name: {
      fin: "B3 Brazilian Split End Bonder ",
      eng: "B3 Brazilian Split End Bonder",
      swe: "B3 Brazilian Split End Bonder",
    },
    price: "20-70€",
    page: "B3 Brazilian Split End Bonder",
    id: 5,
    description: {
      fin: "B3 Brazilian Split End Bonder on lämpöaktivoituva hoitokäsittely erityisesti haaroittuneille latvoille. Se sulkee haaroittuneet latvat välittömästi, antaen uskomattoman kiillon sekä suojan tulevia vaurioita vastaan jopa 4 viikon ajan.",
      eng: "B3 Brazilian Split end Bonder is a heat activated treatment that binds cuticle fibres together instantly sealing split ends whilst preventing breakage for up to 4 weeks. It gives a fabulous shine to the hair.",
      swe: "B3 Brazilian Split end Bonder reparerar slitet och skadat hår omedelbart, samtidigt som det hjälper till att förhindra framtida skador. Det återbygger hårets inre struktur och gör det otroligt glansigt, håller i upp till 4 veckor.",
    },
  },
  {
    name: {
      fin: "K18Hair Peptide Prep Pro chelating hair complex",
      eng: "K18Hair Peptide Prep Pro chelating hair complex",
      swe: "K18Hair Peptide Prep Pro chelating hair complex",
    },
    price: "25-70€",
    page: "K18Hair Peptide Prep Pro chelating hair complex",
    id: 6,
    description: {
      fin: "Poistaa 7 eri metallityyppiä ja mineraalikertymiä hiuksista vain 4 minuutissa! Täysin uusi, ainutlaatuinen teknologia puhdistaa hiuksen metalleista ja mineraalikertymistä valmistaen hiuksen kemialliseen käsittelyyn. Voidaan käyttää kaikkien kemiallisten käsittelyiden kanssa, mutta myös metallinpoistohoitona yksinään, ilman kemiallista käsittelyä.",
      eng: "K18Hair PEPTIDE PREP PRO chelating complex removes 7 different types of metals and mineral buildup from hair in just 4 minutes! A completely new and unique technology cleanses the hair of metals and mineral buildup, preparing the hair for chemical treatment or K18Hair molecular repair. Can be used with all chemical treatments, but also as a stand-alone metal removal treatment without chemical treatment.",
      swe: " Avlägsnar 7 olika metalltyper och mineralavlagringar från håret på bara 4 minuter! Helt ny och unik teknologi rengör håret från metaller och mineralavlagringar för att förbereda håret för kemisk behandling. Kan användas med alla kemiska behandlingar, men även som en ensam metallavlägsnande behandling utan kemisk behandling  ",
    },
  },
];
