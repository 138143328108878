import { ServicesComponent } from "../services/services";
import { ImageSlider } from "../imageSlider/imagesliderComponent";
import { BookTimeBanner } from "../bookTime/booktimeBanner";
import { ServicesBannerList } from "../servicesBannerList/servicesBannerList";
import { FrontpageComponent } from "../frontpage/frontpage";
import { Helmet } from "react-helmet";

export const Homepage = ({ language }) => {
  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Etusivu</title>
        <meta
          name="description"
          property="og:description"
          content="Helmi Helsingistä löydät laajan valikoiman palveluja niin naisille kuin miehille. Meiltä löydät parturi-, kampaamo-, kampaus-, meikki- sekä kynsipalveluita."
        />
        <meta property="og:title" content="Etusivu" />
        <meta property="og:url" content="www.helmihelsinki.fi/etusivu" />
        <meta
          property="og:image"
          content="https://scontent.fqlf1-2.fna.fbcdn.net/v/t39.30808-6/293368231_553616119803362_2817365091336273284_n.jpg?stp=dst-jpg_s960x960&_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=McW5Q9wcB6AAX-IzmcP&_nc_ht=scontent.fqlf1-2.fna&oh=00_AT_hbxD1SoinrUNlrKGicdA5v7CfGTTt4XETAhA-2lFMfQ&oe=62E4D528"
        />
        <meta
          name="keywords"
          content="helmihelsinki, helmi, helsinki, Helmi Helsinki, hairdresser, parturi, Makeup, salon, Helsinki, Mikonkatu, kampaamo, website, parturi-kampaamo, parturikampaamo, kampaaja, kampaamo helsinki, make up, hiukset, hår, frisyr, frisör, barberare, smink, meikkitaiteilija, meikki, häämeikki, hääkampaus, brudkammning, brudsminkning, gellack, geelilakkaus, geelikynnet, kynnet, uppsättning, kampaamo helsinki, paras kampaamo, Mikonkatu 13, helsingin keskusta, kampaus"
        />
      </Helmet>
      <FrontpageComponent language={language} />
      <ImageSlider language={language} />
      <ServicesComponent language={language} />
      <BookTimeBanner language={language} />
      <ServicesBannerList language={language} />
    </div>
  );
};
