import { useState, useEffect } from "react";
import ga from "../../googleAnalytics";
import "./gallery.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { gallery } from "./galleryData";
import { Helmet } from "react-helmet";
import homepagePic from "../../media/images/homepagePic.png";
import { ModalPic } from "./modalPic";

export const GalleryComponent = ({ language }) => {
  const [galleryPics, setGalleryPics] = useState(gallery);
  const [modalShow, setModalShow] = useState(false);
  const [url, setUrl] = useState(null);

  const handleClick = (link) => {
    ga.trackGAEvent("Open_picture", "gallery_pictures", link);
    setUrl(link);
    setModalShow(true);
  };

  useEffect(() => {
    //report to page view
    ga.trackPageView();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Galleria</title>
        <meta
          name="description"
          property="og:description"
          content="Galleriasta löydät näytteitä töistämme."
        />
        <meta property="og:title" content="Gallery" />
        <meta property="og:url" content="www.helmihelsinki.fi/gallery" />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, gallery, pics, pictures, kuvia, kampaus kuvia, kampaamo kuvia, parturi kuvia, bilder, bilder på frisyrer, bilder på hår, bilder på barbering, fades"
        />
      </Helmet>
      <Container className="container-page">
        <Row>
          <Col
            style={{
              textAlign: "center",
              marginTop: "4vh",
              letterSpacing: "1vh",
            }}
          >
            <h1>
              {language === "fin" && "Galleria"}
              {language === "eng" && "Gallery"}
              {language === "swe" && "Galleri"}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              textAlign: "center",
              marginBottom: "5vh",
              letterSpacing: "0.5vh",
            }}
          >
            {language === "fin" && "Löyda töitämme täältä"}
            {language === "eng" && "Find our work here"}
            {language === "swe" && "Hitta våra arbeten här"}
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              textAlign: "center",
              marginBottom: "10vh",
              letterSpacing: "0.5vh",
              fontSize: "18px",
            }}
          >
            {language === "fin" && "Lisää kuvia tulossa"}
            {language === "eng" && "More pictures are coming soon"}
            {language === "swe" && "Fler bilder på kommande"}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Row style={{ display: "flex", flexDirection: "column" }}>
              <Col>
                <h3>
                  {language === "fin" && "Kategoriat"}
                  {language === "eng" && "Categories"}
                  {language === "swe" && "Kategorier"}
                </h3>
              </Col>
              <Col
                className="category-name"
                onClick={() =>
                  setGalleryPics(
                    gallery.filter((pic) => pic.category === "makeup")
                  )
                }
              >
                {language === "fin" && "MEIKKI"}
                {language === "eng" && "MAKEUP"}
                {language === "swe" && "SMINK"}
              </Col>
              <Col
                className="category-name"
                onClick={() =>
                  setGalleryPics(
                    gallery.filter((pic) => pic.category === "hair")
                  )
                }
              >
                {language === "fin" && "HIUKSET"}
                {language === "eng" && "HAIR"}
                {language === "swe" && "HÅR"}
              </Col>
              <Col
                className="category-name"
                onClick={() => setGalleryPics(gallery)}
              >
                {language === "fin" && "KAIKKI"}
                {language === "eng" && "ALL"}
                {language === "swe" && "ALLA"}
              </Col>
            </Row>
          </Col>
          <Col md={8}>
            <Row>
              {galleryPics.length < 1 && (
                <div className="no-pics-div">
                  <h3>
                    {language === "fin" &&
                      "Ei saatavilla vielä. Kuvia tulossa. "}
                    {language === "eng" &&
                      "Not available yet. Pictures will be added soon."}
                    {language === "swe" &&
                      "Bilder finns inte för tillfället. Bilder är på kommande."}
                  </h3>
                  <h6>
                    {language === "fin" &&
                      "Sillä välin voit nähdä töitämme instagramissamme."}
                    {language === "eng" &&
                      "Meanwhile you can check our work on instagram."}
                    {language === "swe" &&
                      "Under tiden kan du se våra arbeten på instagram. "}
                  </h6>
                  <a
                    href="https://www.instagram.com/helmihelsinki/"
                    target="blanket"
                  >
                    <img
                      alt="Instagram"
                      src="https://img.icons8.com/color/2x/instagram-new.png"
                      id="no-gallery-instagram"
                    ></img>
                  </a>
                </div>
              )}
              {galleryPics.length > 0 &&
                galleryPics.map((pic) => (
                  <Col key={pic.id} xs={12} md={3}>
                    <Image
                      className="gallery-pic"
                      src={pic.url}
                      onClick={() => handleClick(pic.url)}
                    ></Image>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <ModalPic
          show={modalShow}
          onHide={() => setModalShow(false)}
          url={url}
        />
      </Container>
    </div>
  );
};
