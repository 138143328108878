import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./services.css";
import ga from "../../googleAnalytics";

export const ServicesComponent = ({ language }) => {
  const navigate = useNavigate();
  const handleNavigate = (location) => {
    ga.trackGAEvent(
      "frontpage_button_click",
      "frontpage_services_buttons",
      location
    );
    navigate(location);
  };
  return (
	<Container className="container-page services-container">
		<Row className="services-row">
		<Col
			className="service-col box-meetUs"
			xs={12}
			md={{ span: 5, offset: 0 }}
			data-aos="fade-right"
			data-aos-delay="50"
			data-aos-duration="1000"
			data-aos-mirror="false"
			data-aos-once="true"
		>
			<Row style={{ height: "100%" }}>
			<div className="overlay-services-box">
				<Col
				className="services-info"
				lg={{ span: 8, offset: 1 }}
				sm={9}
				md={8}
				xs={{ span: 10, offset: 0 }}
				id="service-team-info"
				>
				{language === "fin" && (
					<div>Ammattitaitoinen tiimimme palvelee sinua</div>
				)}
				{language === "eng" && (
					<div>Our professional team welcomes you</div>
				)}
				{language === "swe" && (
					<div>Vårt professionella team betjänar dig</div>
				)}
				</Col>
				<Row>
				<Col xs={{ span: 6, offset: 0 }} md={{ span: 12, offset: 1 }}>
					<Button
					className="services-btn"
					onClick={() => handleNavigate("/meetUs")}
					id="service-team-btn"
					>
					{language === "fin" && "Meistä"}
					{language === "eng" && "Meet us"}
					{language === "swe" && "Möt oss"}
					</Button>
				</Col>
				</Row>
			</div>
			</Row>
		</Col>
		<Col
			className="service-col box-services"
			xs={12}
			md={{ span: 5, offset: 0 }}
			data-aos="fade-left"
			data-aos-delay="50"
			data-aos-duration="1000"
			data-aos-mirror="false"
			data-aos-once="true"
		>
			<Row style={{ height: "100%" }}>
			<div
				className="overlay-services-box"
				style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				}}
			>
				<Col
				className="services-info"
				lg={{ span: 8, offset: 1 }}
				sm={9}
				md={8}
				xs={{ span: 10, offset: 0 }}
				>
				{language === "fin" && (
					<div>
					Meillä on laaja valikoima palveluita jokaiseen tarpeeseen
					</div>
				)}
				{language === "eng" && (
					<div>
					We have a lot of different serivces to support your needs
					</div>
				)}
				{language === "swe" && (
					<div>Vi har olika tjänster för just dina behov</div>
				)}
				</Col>
				<Row>
				<Col xs={{ span: 6, offset: 0 }} md={{ span: 12, offset: 1 }}>
					<Button
					className="services-btn"
					onClick={() => handleNavigate("/services")}
					>
					{language === "fin" && "Palvelut"}
					{language === "eng" && "Services"}
					{language === "swe" && "Tjänster"}
					</Button>
				</Col>
				</Row>
			</div>
			</Row>
		</Col>
		<Col
			className="service-col box-gallery"
			xs={12}
			md={5}
			data-aos="fade-right"
			data-aos-delay="50"
			data-aos-duration="1000"
			data-aos-mirror="false"
			data-aos-once="true"
		>
			<Row style={{ height: "100%" }}>
			<div className="overlay-services-box">
				<Col
				className="services-info"
				lg={{ span: 6, offset: 1 }}
				sm={9}
				md={8}
				xs={{ span: 10, offset: 0 }}
				id="service-gallery-info"
				>
				{language === "fin" && (
					<div>Galleriasta löydät näytteitä töistämme</div>
				)}
				{language === "eng" && (
					<div>In our gallery you can check out some of our work</div>
				)}
				{language === "swe" && (
					<div>I galleriet hittar du bilder på våra arbeten</div>
				)}
				</Col>
				<Row>
				<Col xs={{ span: 6, offset: 0 }} md={{ span: 12, offset: 1 }}>
					<Button
					className="services-btn"
					onClick={() => handleNavigate("/gallery")}
					id="service-gallery-btn"
					>
					{language === "fin" && "Galleria"}
					{language === "eng" && "Gallery"}
					{language === "swe" && "Galleri"}
					</Button>
				</Col>
				</Row>
			</div>
			</Row>
		</Col>

		{/******Fourth Square of services.*******/}
		<Col
			className="service-col box-4"
			xs={12}
			md={5}
			data-aos="fade-left"
			data-aos-delay="50"
			data-aos-duration="1000"
			data-aos-mirror="false"
			data-aos-once="true"
		>
			<Row style={{ height: "100%" }}>
			<div className="overlay-services-box">
				<Col
				className="services-info"
				lg={{ span: 6, offset: 1 }}
				sm={9}
				md={8}
				xs={{ span: 10, offset: 0 }}
				id="service-new"
				>
				{language === "fin" && (
					<div>
					<div id="new-title">Uutuuksia!<br/></div>
					<span id="service-new-span">
					Davines Instant Bonding Glow kiiltoseerumi<br/>
					K18HAIR Pro Chelating Complex
					</span>
					</div>
				)}
				{language === "eng" && (
					<div>
					New!<br/>
					<span id="service-new-span">
					Davines Instant Bonding Glow shine serum<br/>
					K18HAIR Pro Chelating Complex
					</span>
					</div>
				)}
				{language === "swe" && (
					<div>
					<span>Nytt!<br/></span>
					<span id="service-new-span">
					Davines Instant Bonding Glow glansserum<br/>
					K18HAIR Pro Chelating Complex
					</span>
					</div>
				)}
				</Col>
				<Row>
				<Col xs={{ span: 6, offset: 0 }} md={{ span: 12, offset: 1 }}>
					<Button
					className="services-btn"
					id="service-new-btn"
					onClick={()=>handleNavigate("/uutuudet")}
					>
						{language === "fin" && "Lue lisää"}
						{language === "eng" && "Read More"}
						{language === "swe" && "Läs mera"}
					</Button>
				</Col>
				</Row>
			</div>
			</Row>
		</Col>
		</Row>
	</Container>
	);
};
