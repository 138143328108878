import { Card, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import './cardService.css'
import ga from '../../googleAnalytics';
export const CardService = (props) => {
    
    return(
    <Card style={{ width: '100%', border: 0}} className="card">
        <Card.Img className="service-picture" variant="top" src={props.url} alt={props.title.fin}/>
        <Card.Body>
            <Card.Title className="card-title">
                <Col>
                    <Link className="LinkComponent" to={`/services/${props.path.toLowerCase()}`} onClick={()=>ga.trackGAEvent('choose_services', 'services', props.path.toLowerCase())}>
                        {props.language==='fin' && props.title.fin}
                        {props.language==='eng' && props.title.eng}
                        {props.language==='swe' && props.title.swe}
                    </Link>
                </Col>
            </Card.Title>
        </Card.Body>
        </Card>
    )
}