import { Navbar, Container, NavDropdown, Nav, Image } from "react-bootstrap";
import "./navbarComponent.css";
import helmiHelsinkiLogo from "../../media/images/Helmihelsinki.png";
import { LinkContainer } from "react-router-bootstrap";
import { Cookies } from "react-cookie-consent";
import ga from "../../googleAnalytics";

export const NavbarComponent = ({ setLanguage, language, allowCookies }) => {
  const socialMedia = () => {
    if (language === "fin") {
      return "Sosiaalinen media";
    } else if (language === "eng") {
      return "Social Media";
    } else {
      return "Sociala Medier";
    }
  };

  const setCookies = (lang) => {
    ga.trackGAEvent("choose_language", "language", lang);
    if (allowCookies) {
      Cookies.set("language", lang);
      setLanguage(lang);
    } else {
      setLanguage(lang);
    }
  };

  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      bg="light"
      expand="lg"
      className="nav-bar"
      style={{ cursor: "pointer" }}
    >
      <Container>
        <Navbar.Brand>
          <Image
            id="navbar-logo"
            src={helmiHelsinkiLogo}
            alt="Helmi Helsinki logo"
          ></Image>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <LinkContainer
              to="/"
              onClick={() =>
                ga.trackGAEvent("navbar_choose_page", "navbar_links", "/")
              }
            >
              <Nav.Link>
                {language === "fin" && "Etusivu"}
                {language === "eng" && "Home"}
                {language === "swe" && "Startsida"}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/services"
              onClick={() =>
                ga.trackGAEvent(
                  "navbar_choose_page",
                  "navbar_links",
                  "/services"
                )
              }
            >
              <Nav.Link>
                {language === "fin" && "Palvelut"}
                {language === "eng" && "Services"}
                {language === "swe" && "Tjänster"}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/prices"
              onClick={() =>
                ga.trackGAEvent("navbar_choose_page", "navbar_links", "/prices")
              }
            >
              <Nav.Link>
                {language === "fin" && "Hinnasto"}
                {language === "eng" && "Prices"}
                {language === "swe" && "Prislista"}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/booking"
              onClick={() =>
                ga.trackGAEvent(
                  "navbar_choose_page",
                  "navbar_links",
                  "/booking"
                )
              }
            >
              <Nav.Link>
                {language === "fin" && "Ajanvaraus"}
                {language === "eng" && "Book time"}
                {language === "swe" && "Boka tid"}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/meetUs"
              onClick={() =>
                ga.trackGAEvent("navbar_choose_page", "navbar_links", "/meetUs")
              }
            >
              <Nav.Link>
                {language === "fin" && "Meistä"}
                {language === "eng" && "Meet us"}
                {language === "swe" && "Möt oss"}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/contacts"
              onClick={() =>
                ga.trackGAEvent(
                  "navbar_choose_page",
                  "navbar_links",
                  "/contacts"
                )
              }
            >
              <Nav.Link>
                {language === "fin" && "Yhteystiedot"}
                {language === "eng" && "Contacts"}
                {language === "swe" && "Kontaktinformation"}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="/gallery"
              onClick={() =>
                ga.trackGAEvent(
                  "navbar_choose_page",
                  "navbar_links",
                  "/gallery"
                )
              }
            >
              <Nav.Link>
                {language === "fin" && "Galleria"}
                {language === "eng" && "Gallery"}
                {language === "swe" && "Galleri"}
              </Nav.Link>
            </LinkContainer>
            <NavDropdown
              menuVariant="dark"
              title={socialMedia()}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                href="https://www.instagram.com/helmihelsinki/"
                target="blank"
                onClick={() =>
                  ga.trackGAEvent(
                    "social_media_follow",
                    "navbar_links",
                    "instagram"
                  )
                }
              >
                Instagram
                <Image
                  className="socialMedia-logo"
                  alt="instagram-logo"
                  src="https://img.icons8.com/color/344/instagram-new--v1.png"
                ></Image>
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.facebook.com/HelmiHelsinki13/"
                target="blank"
                onClick={() =>
                  ga.trackGAEvent(
                    "social_media_follow",
                    "navbar_links",
                    "facebook"
                  )
                }
              >
                Facebook
                <Image
                  className="socialMedia-logo"
                  alt="facebook-logo"
                  src="https://img.icons8.com/color/344/facebook.png"
                ></Image>
              </NavDropdown.Item>
            </NavDropdown>
            <div className="lng-container">
              <Image
                onClick={() => setCookies("fin")}
                className="lng-flags"
                alt="finnishLanguage"
                src="https://img.icons8.com/color/344/finland.png"
              ></Image>
              <Image
                onClick={() => setCookies("swe")}
                className="lng-flags"
                alt="swedishLanguage"
                src="https://img.icons8.com/color/344/sweden.png"
              ></Image>
              <Image
                onClick={() => setCookies("eng")}
                className="lng-flags"
                alt="englishLanguage"
                src="https://img.icons8.com/color/344/great-britain.png"
              ></Image>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
