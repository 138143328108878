import { Accordion, Row, Col } from "react-bootstrap";
import ga from '../googleAnalytics';

export const AccordionComponent = (props) => {
  return (
    <Accordion.Item eventKey={props.id - 1}>
      <Accordion.Header onClick={()=>ga.trackGAEvent('check_services', 'individualServices', props.title.fin)}>
        {props.language === "fin" && props.title.fin}
        {props.language === "eng" && props.title.eng}
        {props.language === "swe" && props.title.swe}
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col xs={12}>
            {props.language === "fin" && props.description.fin}
            {props.language === "eng" && props.description.eng}
            {props.language === "swe" && props.description.swe}
          </Col>
          <Col xs={12}>
            <strong>{props.price}</strong>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};
