import gallery2 from "../../media/images/gallery2.jpeg";
import gallery3 from "../../media/images/updo.jpeg";
import gallery4 from "../../media/images/gallerypic.jpeg";
import gallery6 from "../../media/images/gallery6.jpeg";
import gallery14 from "../../media/images/jojjo.jpeg";

export const gallery = [
  {
    id: 0,
    url: gallery3,
    category: "hair",
  },
  {
    id: 1,
    url: gallery2,
    category: "hair",
  },
  {
    id: 2,
    url: gallery4,
    category: "hair",
  },
  {
    id: 3,
    url: gallery6,
    category: "hair",
  },
  {
    id: 4,
    url: gallery14,
    category: "makeup",
  },
];
