import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ga from '../googleAnalytics';

export const CheckAllPrices = ({language}) => {

	const navigate = useNavigate();
	const handleNavigate = (location, label) =>{
		ga.trackGAEvent('check_prices', 'individualServices_button', label);
		navigate(location)
	}

	return (
		<>
			<div></div>
			<Row>
				<Col className="flex-center-row">
				<Button variant="dark" onClick={() => handleNavigate("/prices", 'check_prices')}>
					{language === "fin" && "Katso kaikki hinnat"}
					{language === "eng" && "Check all prices"}
					{language === "swe" && "Kolla alla priser"}
				</Button>
				</Col>
			</Row>
		</>
	)
}