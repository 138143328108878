import { Col, Container, Row, Image, Button, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SideNavServices } from "./sideNavServices";
import { hoidot } from "../../prices/listOfPrices";
import "./individualServices.css";
import { AccordionComponent } from "../../accordion";
import Helmet from "react-helmet";
import homepagePic from "../../../media/images/homepagePic.png";
import headPic from "../../../media/images/hiushoidot/top_pic.jpg";
import video1 from "../../../media/videos/hairtreatmentVideo.mp4";
import video2 from "../../../media/videos/keratinVideo.mp4";
import { useEffect } from "react";
import ga from '../../../googleAnalytics';
import { CheckAllPrices } from "../../CheckAllPrices";

export const MuutPage = ({ language }) => {
  const navigate = useNavigate();
  const handleNavigate = (location, label) => {
    ga.trackGAEvent('follow_button', 'individualServices_button', label);
    navigate(location);
  }

  const allServices = hoidot.filter((ser) => typeof ser.id === "number");

  useEffect(()=> {
    ga.trackPageView();
  })

  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Hoidot</title>
        <meta
          name="description"
          property="og:description"
          content="Hiushoitomme löydät täältä."
        />
        <meta property="og:title" content="Muut" />
        <meta property="og:url" content="www.helmihelsinki.fi/services/muut" />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, Services, palvelut, muut hiushoito, tehohoito, kuivat hiukset, kiiltävät hiukset, frameda, brazilian bond builder, brazilian split end bonder, hh simonsen, hh simonsen keratin boost, keratin boost, davines, four reasons, evo, hårinpackning, vårdande hårbehandling, hårbehandling, sköt om håret, upeat hiukset, hairtreatment, shiny hair, vårdat hår, glansigt hår"
        />
      </Helmet>
      <Container className="container-page">
        <Row>
          <Col md={4}>
            <Image
              alt="back button"
              src="https://img.icons8.com/ios/344/circled-left-2.png"
              className="service-back-btn"
              onClick={() => handleNavigate("/services", 'return')}
            ></Image>
          </Col>
          <Col
            md={8}
            className="individual-service-title"
          >
            <h1>
              {language==='fin' && "Hiushoidot"}
              {language==='eng' && "Hair treatments"}
              {language==='swe' && "Hårbehandlingar"}
            </h1>
          </Col>
        </Row>
        <Row style={{ margin: "5vh 0" }}>
          <Col md={3} className="hiddePhone">
            <SideNavServices language={language}/>
          </Col>
          <Col md={9}>
            <Row className="individual-service-info">
              <Col xs={12} lg={5}>
                <Image
                  alt="haircut"
                  className="individual-service-img"
                  src={headPic}
                ></Image>
              </Col>
              <Col
                style={{ fontSize: "1.2rem", liheHeight: "1.5rem" }}
                xs={12}
                lg={7}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <Col>
                  {language==='fin' && <div>Hiusten värjäysten ja leikkausten lomassa hiuksia on myös hyvä hoitaa ja kosteuttaa. Meillä on käytössä B3 Brazilian Bond Builder, B3 Brazilian Split End Bonder sekä HH Simonsenin Keratin boost hoidot. <br/><br/> Brazilian Bond Builder on vaalennusten ja värjäyksien yhteydessä käytettävä, yksivaiheinen hiusten vaurioitumista ehkäisevä, suojaava ja hiusten kuntoa parantava tuote. Se vahvistaa hiuksen sisäistä rakennetta uudelleenrakentamalla sekä suojaamalla hiuksen kuitukerroksessa olevia poikittaissidoksia. Teho perustuu vaikuttavaan aineeseen, joka on hydrogenoidun risiiniöljyn ja sebasiinihaponpolymeeri. Hiukset pysyvät paremmassa kunnossa isoissakin värimuutoksissa ja väri säilyy kauniina pidempään. <br/><br/>B3 Brazilian Split End Bonder on lämpöaktivoituva hoitokäsittely erityisesti haaroittuneille latvoille. Se sulkee haaroittuneet latvat välittömästi, antaen uskomattoman kiillon sekä suojan tulevia vaurioita vastaan. Erinomainen apu silloin, kun hius on katkeileva, pörröinen, haaroittuva ja hauras ja kun halutaan parantaa hiusten kuntoa, auttaa hiusten kasvatusprosessissa. Voidaan tehdä hiustenleikkauksen tai värjäyksen yhteydessä. Optimaalinen tulos saavutetaan toistamalla hoito neljän viikon välein. <br/><br/>
                  HH Simonsen Keratin Boost hoito säilyttää kosteuden hiuksessa, tehden hiuksista kiiltävät ja sileät. Hoito voidaan tehdä joko pesupaikalla tai Keratin Boost suoristusrautaa käyttämällä. Suoristusrautaa käyttäessä, keratiini lisätään raudan sisälle, jättäen hiukset silkkisen pehmeiksi ja kiiltäviksi.<br></br><br></br>
				  K18Hair PEPTIDE PREP PRO chelating complex poistaa 7 eri metallityyppiä ja mineraalikertymiä hiuksista vain 4 minuutissa! Täysin uusi, ainutlaatuinen teknologia puhdistaa hiuksen metalleista ja mineraalikertymistä valmistaen hiuksen kemialliseen käsittelyyn. Voidaan käyttää kaikkien kemiallisten käsittelyiden kanssa, mutta myös metallinpoistohoitona yksinään, ilman kemiallista käsittelyä.
                  </div>}
                  {language=== 'eng' && <div>
                    We are using the B3 Brazilian Bond Builder, Brazilian Split End Bonder and HH Simonsen Keratin Boost treatments.  <br/><br/> Brazilian Bond Builder is used when bleching or coloring the hair, it is a one step protector of the hair preventing the hair getting damaged when coloring the hair. It strengthens the hair through rebuilding and protecting the hair from the inside. The hair stays in better condition even in bigger hairtransformations and the color stays beautiful for longer. <br/><br/> B3 Brazilian Split end Bonder is a heat activated treatment that binds cuticle fibres together instantly sealing split ends whilst preventing breakage for up to four weeks. It gives a fabulous shine to the hair, it is an excellent help when the hair is frizzy or damaged, you want to improve the hairs condition and grow your hair. For optimal result the service should be done every four weeks. <br/><br/> The HH Simonsen Keratin Boost treatment keeps the hair hydrated, making the hair shiny and sleek. The treatment can be done at the washing place or with the HH Simonsen Keratin Boost flat iron. When using the flat iron the keratin is put inside the iron, leaving the hair soft and shiny. <br></br><br></br>
					K18Hair PEPTIDE PREP PRO chelating complex removes 7 different types of metals and mineral buildup from hair in just 4 minutes! A completely new and unique technology cleanses the hair of metals and mineral buildup, preparing the hair for chemical treatment or K18Hair molecular repair. Can be used with all chemical treatments, but also as a stand-alone metal removal treatment without chemical treatment.
                    </div>}
                  {language==='swe' && <div>Det är även bra att vårda håret. Du kan lätt göra det i samband med en färgning eller klippning. Vi använder B3 Brazilian Bond Builder, Brazilian Split end Bonder och HH Simonsens Keratin Boost behandlingar. <br/><br/> B3 Brazilian Bond Builder används när man bleker eller färgar håret. Det skyddar och stärker håret inifrån. Det binder och fyller i trasiga fibrer i håret och skyddar det mot nya skador. Håret hålls i bättre skick även vid större färgförändringar och färgen hålls glansig och vacker längre. <br/><br/> B3 Brazilian Split end Bonder reparerar slitet och skadat hår omedelbart, samtidigt som det hjälper till att förhindra framtida skador. Det återbygger hårets inre struktur och gör det otroligt glansigt. Passar utmärkt när man vill låta håret växa ut. För optimalt resultat borde servicen göras var fjärde vecka. <br/><br/> HH Simonsens Keratin Boost behandling fuktger håret och ger det en underbar glans. Behandlingen kan göras vid tvättstället eller med HH Simonsens Keratin Boost plattång. När man använder plattången sätter man keratinet i plattången, det lämnar håret superglansigt och lent. <br></br><br></br>
				  K18Hair PEPTIDE PREP PRO chelating complex Avlägsnar 7 olika metalltyper och mineralavlagringar från håret på bara 4 minuter! Helt ny och unik teknologi rengör håret från metaller och mineralavlagringar för att förbereda håret för kemisk behandling. Kan användas med alla kemiska behandlingar, men även som en ensam metallavlägsnande behandling utan kemisk behandling.</div>}
                  
                  </Col>
                  <Col>
                    <Row>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.facebook.com/HelmiHelsinki13/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                            onClick={()=>ga.trackGAEvent('follow_button', 'individualServices_button', 'facebook')}
                          >
                            <Image
                              alt="Facebook"
                              src="https://img.icons8.com/ios/344/facebook-new.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                              onClick={()=>ga.trackGAEvent('follow_button', 'individualServices_button', 'instagram')}
                            ></Image>
                            Facebook
                          </Button>
                        </a>
                      </Col>
                      <Col md={3} className="service-btn-box">
                        <a
                          className="LinkComponent"
                          target="blank"
                          href="https://www.instagram.com/helmihelsinki/"
                        >
                          <Button
                            variant="outline-secondary"
                            className="button-service-sm"
                          >
                            <Image
                              alt="Instagram"
                              src="https://img.icons8.com/ios/344/instagram-new--v1.png"
                              style={{ width: "16px", marginRight: "1vh" }}
                            ></Image>
                            Instagram
                          </Button>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="indService-pics">
          <Col xs={12} md={6} lg={7}>
            <video
              alt="värjäykset"
              className="service-pic-list"
              onClick={()=>ga.trackGAEvent('play_video', 'hoidot_videos', 'B3_Split_End_Bonder')}
              controls>
                <source
                  src={video1}
                  type="video/mp4"
                />
            </video>
            <p style={{textAlign: 'center'}}>B3 Split End Bonder</p>
          </Col>
          <Col xs={12} md={6} lg={{offset:1, span:4}}>
            <video
              alt="värjäykset"
              className="service-pic-list"
              onClick={()=>ga.trackGAEvent('play_video', 'hoidot_videos', 'Keratin_Boost_treatment')}
              controls>
                <source
                  src={video2}
                  type="video/mp4"
                />
            </video>
            <p style={{textAlign: 'center'}}>Keratin Boost treatment</p>
          </Col>
          
        </Row>
        <Row>
          <Col className="flex-center-column">
            <h4>
              {language==='fin' && "Hius hoitomme"}
              {language==='eng' && "Our hair treatments"}
              {language==='swe' && "Våra hårbehandlingar"}
            </h4>
          </Col>
        </Row>
        <section style={{ padding: "5vh 10%" }}>
          <Accordion defaultActiveKey="0">
            {allServices.map((item) => (
              <AccordionComponent
                id={item.id}
                title={item.name}
                key={item.id}
                description={item.description}
                price={item.price}
                language={language}
              />
            ))}
          </Accordion>
        </section>
		<CheckAllPrices language={language} />
      </Container>
    </div>
  );
};
